import { AgoraWebClient } from "./agora-web-client";

export interface ChatMessage {
  ts?: number;
  text?: string;
  id: string;
}

export interface AgoraMediaStream {
  streamID: number;
  local: boolean;
  stream: any;
  video: boolean;
  audio: boolean;
  playing?: boolean;
  isMainStream?: boolean;
  AudioTrack?: any;
  VideoTrack?: any;
  name?: string;
}

export class AgoraStream {
  constructor(
    public readonly stream: any = {
      audioTrack: "",
      videoTrack: "",
    },
    public readonly streamID: number = streamID,
    public readonly local: boolean = local,
    public readonly audio: boolean = audio,
    public readonly video: boolean = video,
    public readonly name: string,
    public readonly profile_pic: string
  ) {}
}

export class AgoraClient {
  constructor(
    public readonly client: AgoraWebClient = client,
    public readonly channelID: number = channelID,
    public readonly local: boolean = local
  ) {}
}
