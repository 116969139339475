import { ChatCmdType } from "@/utils/agora-rtm-client";
import EventEmitter from "events";
import { abbreviateNumber } from "@/utils/helper";
import AgoraRTC from "agora-rtc-sdk-ng";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  AgoraStream,
  AgoraMediaStream,
  ChatMessage,
  AgoraClient,
} from "@/utils/types";
import { Subject } from "rxjs";
import { Map, Set, List } from "immutable";
import { get, set } from "lodash";
import AgoraRTMClient, { ChannelParams } from "@/utils/agora-rtm-client";
import { AgoraWebClient } from "@/utils/agora-web-client";
import { APP_ID } from "@/utils/config";
import Axios from "axios";
import { ApiUrls } from "@/utils/api/constant/api.constant";

export interface AgoraUser {
  uid: number;
  role: number;
  video: number;
  audio: number;
}

export interface Me extends AgoraUser {
  channelName: string;
  publishLocalStream?: boolean;
  mainLiveStreamID?: number; // this is only used when we are are live show screen to handle default main stream.
  info?: any;
}

type RtcState = {
  published: boolean;
  joined: boolean;
  users: Set<number>;
  localStream: AgoraMediaStream | null;
  remoteStreams: Map<string, AgoraMediaStream>;
  injectedStream?: AgoraMediaStream | null;
  injected: boolean; // rtmp injection by host
  isAutoPlayFailed: boolean;
};

export type MediaDeviceState = {
  microphoneId: string;
  speakerId: string;
  cameraId: string;
  speakerVolume: number;
  camera: number;
  microphone: number;
  speaker: number;
};

export type RtmState = {
  joined: boolean;
};

export type EventRoomState = {
  appID: string;
  rtcToken: string;
  me: Me;
  usersAsAudience: Set<string>;
  rtc: RtcState;
  rtm: RtmState;
  mediaDevice: MediaDeviceState;
  messages: List<ChatMessage>;
  viewerCount: number;
  isLive: boolean;
  networkQuality: number;
  currentMicrophone: any;
  currentCamera: any;
  currentSpeaker: any;
  gridView: boolean;
  hasStarted: boolean;
};

export class EventRoomStore {
  private subject: Subject<EventRoomState> | null;
  public _state: EventRoomState;

  get state() {
    return this._state;
  }

  set state(newState) {
    this._state = newState;
  }
  public readonly defaultState: EventRoomState = Object.freeze({
    appID: APP_ID,
    rtcToken: "",
    me: {
      uid: 0,
      role: 1,
      video: 1,
      audio: 1,
      channelName: "",
      publishLocalStream: false,
      mainLiveStreamID: 0,
      info: {
        uid: 0,
        name: "",
        role: "",
        profile_pic: "",
        guide: {
          id: 0,
          name: "",
          profile_pic: "",
          profile_url: "",
        },
      },
      host: {
        id: 0,
        name: "",
      },
      residence: {
        id: 0,
        name: "",
      },
    },
    usersAsAudience: Set<string>(),
    isLive: true,
    networkQuality: 0,

    rtm: {
      joined: false,
    },
    rtc: {
      published: false,
      joined: false,
      users: Set<number>(),
      localStream: null,
      remoteStreams: Map<string, AgoraMediaStream>(),
      injectedStream: null,
      injected: false,
      isAutoPlayFailed: true,
    },
    mediaDevice: {
      microphoneId: "",
      speakerId: "",
      cameraId: "",
      speakerVolume: 100,
      camera: 0,
      speaker: 0,
      microphone: 0,
    },
    currentMicrophone: "",
    currentCamera: "",
    currentSpeaker: "",
    messages: List<ChatMessage>(),
    viewerCount: 0,
    gridView: false,
    hasStarted: Boolean(sessionStorage.getItem("visitStarted")) || false,
  });

  public rtmClient: AgoraRTMClient;
  public rtcClient: AgoraWebClient;
  public rtcFanWallClients: AgoraClient[];
  // public rtcAudioClient: AgoraWebClient; //this is the client which will be usnig it's own channel to publish audience applause audio
  public _emitter: EventEmitter;

  constructor() {
    this.subject = null;
    this._state = {
      ...this.defaultState,
    };
    this.rtmClient = new AgoraRTMClient();
    this.rtcClient = new AgoraWebClient({ eventRoomStore: this });
    this.rtcFanWallClients = [];
    // this.rtcAudioClient = new AgoraWebClient({ eventRoomStore: this });
    this._emitter = new EventEmitter();
  }

  initialize() {
    this.subject = new Subject<EventRoomState>();
    this.state = {
      ...this.defaultState,
    };
    this.subject.next(this.state);
  }

  subscribe(updateState: any) {
    this.initialize();
    this.subject && this.subject.subscribe(updateState);
  }

  unsubscribe() {
    this.subject && this.subject.unsubscribe();
    this.subject = null;
  }

  commit(state: EventRoomState) {
    this.subject && this.subject.next(state);
  }

  updateState(rootState: EventRoomState) {
    this.state = {
      ...this.state,
      ...rootState,
    };
    this.commit(this.state);
  }

  addLocalStream(stream: AgoraStream) {
    this.updateLocalStream(stream);
  }

  updateAutoPlayFailedStatus(flag: boolean) {
    this.state = {
      ...this.state,
      rtc: {
        ...this.state.rtc,
        ["isAutoPlayFailed"]: flag,
      },
    };
    this.commit(this.state);
  }

  addInjectedStream(stream: AgoraStream, key: string = "rtc") {
    this.updateLocalStream(stream, "injectedStream");
  }

  handleMainExit(role: any, redirect_url: any) {
    sessionStorage.removeItem("visitStarted");
    window.location.href = redirect_url;
  }

  removeLocalStream(stream: any = null, key: string = "localStream") {
    try {
      const localStream =
        key === "localStream" ? this.state.rtc.localStream : "";
      if (
        localStream &&
        localStream.stream.videoTrack &&
        localStream.stream.videoTrack.isPlaying()
      ) {
        //localStream.stream.stop();
      }
      this.updateLocalStream(stream, key);
    } catch (e) {}
  }

  removeInjectedStream = () => {
    this.state.rtc.injectedStream
      ? this.state.rtc.injectedStream.stream?.videoTrack.stop()
      : null;
    this.updateLocalStream(null, "injectedStream");
  };

  updateLocalStream(stream: any = null, key: string = "localStream") {
    this.state = {
      ...this.state,
      rtc: {
        ...this.state.rtc,
        [key]: stream,
      },
    };
    this.commit(this.state);
  }

  addRTCUser(uid: number, key: string = "rtc") {
    const prevState = this.state.rtc;
    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        users: prevState.users.add(uid),
      },
    };
    this.commit(this.state);
  }

  removePeerUser(uid: number, key: string = "rtc") {
    const prevState = this.state.rtc;
    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        users: prevState.users.delete(uid),
      },
    };
    this.commit(this.state);
  }

  addRemoteStream(stream: AgoraStream, key: string = "rtc") {
    const prevState = this.state.rtc;
    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        remoteStreams: prevState.remoteStreams.set(
          `${stream.streamID}`,
          stream
        ),
      },
    };

    this.commit(this.state);
  }

  removeRemoteStream(uid: number, key: string = "rtc") {
    const prevState = this.state.rtc;
    const remoteStream = prevState.remoteStreams.get(`${uid}`);
    // if (platform === 'web') {
    //   if (remoteStream && remoteStream.stream && remoteStream.stream.isPlaying()) {

    //     remoteStream.stream.stop();
    //   }
    // }
    this.state = {
      ...this.state,
      [key]: {
        ...prevState,
        remoteStreams: prevState.remoteStreams.delete(`${uid}`),
      },
    };
    if (
      typeof remoteStream?.stream.audioTrack != "undefined" &&
      remoteStream?.stream.audioTrack != null
    ) {
      remoteStream ? remoteStream.stream?.audioTrack?.stop() : null;
    }
    if (
      typeof remoteStream?.stream.videoTrack != "undefined" &&
      remoteStream?.stream.videoTrack != null
    ) {
      remoteStream ? remoteStream.stream?.videoTrack.stop() : null;
    }
    this.commit(this.state);
  }

  updateDevice(state: MediaDeviceState) {
    this.state = {
      ...this.state,
      mediaDevice: state,
    };
    this.commit(this.state);
  }

  setMeAttr(key: string, uid: number) {
    this.state = {
      ...this.state,
      me: {
        ...this.state.me,
        [key]: uid,
      },
    };
    this.commit(this.state);
  }

  setRTCToken(token: string) {
    this.state = {
      ...this.state,
      rtcToken: token,
    };
    this.commit(this.state);
  }

  setRTCJoined(joined: boolean) {
    this.state = {
      ...this.state,
      rtc: {
        ...this.state.rtc,
        joined: joined,
      },
    };
    this.commit(this.state);
  }

  updateChatMessage(msg: ChatMessage) {
    this.state = {
      ...this.state,
      messages: this.state.messages.push(msg),
    };

    this.commit(this.state);
  }

  updateGridView(flag: boolean) {
    this.state = {
      ...this.state,
      gridView: flag,
    };
    this.commit(this.state);
    if (flag) {
      sessionStorage.setItem("pinid", "0");
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
    }
  }

  hasVisitStarted(flag: boolean) {
    this.state = {
      ...this.state,
      hasStarted: flag,
    };
    this.commit(this.state);
  }

  async sendMessage(data: ChannelParams) {
    try {
      if (!this.state.rtm.joined) return;
      // send data to channel
      await this.rtmClient.sendChannelMessage(data);

      // if msg type is chat msg
      if (data.type === ChatCmdType.chat) {
        this.updateChatMessage(data.msgData);
      }
    } catch (e) {}
  }

  async exitAll() {
    try {
      try {
        await this.rtcClient.exit();
        let noStreamElem = document.querySelector(
          ".large-class .main-stream-section .main-stream.no-video"
        ) as HTMLElement;
        if (noStreamElem != null) {
          noStreamElem.style.backgroundColor = "transparent";
        }
      } catch (err) {
        console.warn(err);
      }
    } finally {
    }
  }

  private compositeMe(params: Partial<Me>): Me {
    const newMe: Me = { ...this.state.me };
    for (const prop in params) {
      if (newMe.hasOwnProperty(prop) && params.hasOwnProperty(prop)) {
        set(newMe, prop, get(params, prop, ""));
      }
    }
    return newMe;
  }

  async updateLocalMe(params: Partial<Me>) {
    const { ...meParams } = params;
    const newMe = this.compositeMe(meParams);

    this.state = {
      ...this.state,
      me: {
        ...this.state.me,
        ...newMe,
      },
    };
    this.commit(this.state);
  }

  async mute(uid: string, type: string) {
    const me = this.state.me;
    if (`${me.uid}` === `${uid}`) {
      const streamType = "localStream";
      const localStream: any = this.state.rtc[streamType];
      if (localStream) {
        localStream[type] = false;
        this.updateLocalStream(localStream, streamType);
      }
    } else {
      const remoteStream: any = this.state.rtc.remoteStreams.get(`${uid}`);
      if (remoteStream) {
        remoteStream[type] = false;
        this.addRemoteStream(remoteStream);
        //this.updateLocalStream(remoteStream, 'rtc');
      } else {
        //this.addRemoteStream(remoteStream);
      }
    }
  }

  async updateRemoteDetails(
    type: string,
    streamID: any,
    track: any,
    details: any
  ) {
    // const remoteStream: any = this.state.rtc.remoteStreams.get(`${streamID}`);
    //remoteStream[type] = track;
    //this.addRemoteStream(remoteStream);
    // let name = details[0] ? details[0].user_name : "";
    const name = details?.name;
    const prevState = this.state.rtc;
    let remoteStream = prevState.remoteStreams.get(`${streamID}`) as any;
    let key = "remoteStream";

    if (typeof remoteStream == "undefined") {
      return;
    }

    remoteStream["stream"][type] = track;
    if (type == "videoTrack") {
      remoteStream["video"] = true;
    } else {
      remoteStream["audio"] = true;
    }
    remoteStream["name"] = name;

    if (key == "injectedStream") {
      this.state = {
        ...this.state,
        ["rtc"]: {
          ...prevState,
          injectedStream: remoteStream,
        },
      };
    } else {
      this.state = {
        ...this.state,
        ["rtc"]: {
          ...prevState,
          remoteStreams: prevState.remoteStreams.set(
            `${streamID}`,
            remoteStream
          ),
        },
      };
    }

    this.commit(this.state);
  }

  async unmute(uid: string, type: string) {
    const me = this.state.me;
    if (`${me.uid}` === `${uid}`) {
      const streamType = "localStream";
      const localStream: any = this.state.rtc[streamType];
      localStream[type] = true;
      this.updateLocalStream(localStream, streamType);
    } else {
      const remoteStream: any = this.state.rtc.remoteStreams.get(`${uid}`);
      if (remoteStream) {
        remoteStream[type] = true;
        this.addRemoteStream(remoteStream);
        //this.updateLocalStream(remoteStream, 'rtc');
      } else {
        //this.addRemoteStream(remoteStream);
      }
    }
  }

  async loginAndJoin(token: string) {
    const { uid, channelName } = this.state.me;
    // const rtmToken = null;

    try {
      await this.rtmClient.login(APP_ID, uid.toString(), token);
      let res = await this.rtmClient.join(channelName);
      const viewerCount = await this.getViewerCount([channelName]);
      const formatedNumber = abbreviateNumber(viewerCount) as any;
      const usersCount = formatedNumber[channelName];
      this.rtmClient.setUserExtraAttributes(this.state.me.info.name);
      this.state = {
        ...this.state,
        rtm: {
          ...this.state.rtm,
          joined: true,
        },
        viewerCount: viewerCount[channelName],
      };
      this.commit(this.state);
      return;
    } catch (err) {
      if (this.rtmClient._logged) {
        await this.rtmClient.logout();
      }
      throw err;
    }
  }

  async getViewerCount(channel: string[]) {
    try {
      const viewerCount = await this.rtmClient.getChannelMemberCount(channel);
      return viewerCount;
    } catch (e) {}
  }

  updateViewerCount = (count: number) => {
    this.state = {
      ...this.state,
      viewerCount: count,
    };
    this.commit(this.state);
  };

  async getMembersList(channel: string) {
    try {
      if (!channel) return;
      const res: string[] = await this.rtmClient.getChannelMembersList(channel);

      const membersList = Set(res);
      // filter membersList by audience only
      const updatedMembersList = this.filterMembersList(membersList);
      this.state = {
        ...this.state,
        usersAsAudience: updatedMembersList,
      };
      this.commit(this.state);
    } catch (e) {}
  }

  filterMembersList = (membersList: Set<string>) => {
    const remoteUsers = this.state.rtc.users;
    let updatedMemberList = membersList;
    if (membersList.size) {
      for (let host of remoteUsers) {
        updatedMemberList = updatedMemberList.delete(host.toString());
      }
      updatedMemberList = updatedMemberList.delete(String(this.state.me.uid));
    }
    return updatedMemberList;
  };

  handleStreamControlClick = (type: string, active: boolean) => {
    const me = this.state.me;
    if (!me.uid) return console.warn("please confirm joined rtc");
    const targetUser = me; //eventRoomState.users.get(`${me.uid}`);
    if (!targetUser) return;
    const targetUid = String(targetUser.uid);
    if (type == "video" || type == "audio") {
      if (active) {
        this.mute(targetUid, type);
      } else {
        this.unmute(targetUid, type);
      }
    }
  };

  handleRemoteAudio = (memberId: any, status: any) => {
    let audioData = {
      cmd: ChatCmdType.muteAudio,
      msgData: {},
    };
    if (status == "unmute") {
      audioData.cmd = ChatCmdType.unmuteAudio;
    }
    this.rtmClient.sendPeerMessage(memberId.toString(), audioData);
  };

  handleRemoteVideo = (memberId: any, status: any) => {
    let videoData = {
      cmd: ChatCmdType.muteVideo,
      msgData: {},
    };
    if (status == "unmute") {
      videoData.cmd = ChatCmdType.unmuteVideo;
    }
    this.rtmClient.sendPeerMessage(memberId.toString(), videoData);
  };

  handleRemoteAudioForAll = (status: any) => {
    let audioData = {
      type: ChatCmdType.muteAudio,
      msgData: { streamID: eventRoomStore.state.me.uid, type: "audio" },
    };

    if (status == "unmute") {
      audioData.type = ChatCmdType.unmuteAudio;
    }
    this.rtmClient.sendChannelMessage(audioData);
  };

  handleRemoteVideoForAll = (status: any) => {
    let videoData = {
      type: ChatCmdType.muteVideo,
      msgData: { streamID: eventRoomStore.state.me.uid, type: "video" },
    };

    if (status == "unmute") {
      videoData.type = ChatCmdType.unmuteVideo;
    }

    this.rtmClient.sendChannelMessage(videoData);
  };

  setNetworkQuality = (value: number) => {
    this.state = {
      ...this.state,
      networkQuality: value,
    };
    this.commit(this.state);
  };

  getAgoraTokens = async (
    channelName: any,
    userId: any,
    type: string = "rtc"
  ) => {
    const res = await Axios.post(ApiUrls.AgoraUrl, {
      channelName,
      userId: `${userId}`,
    });
    const { rtcToken, rtmToken } = res?.data?.data;
    return type == "rtc" ? rtcToken : rtmToken;
  };

  getUserNameById = (userId: any) => {
    return userId == this.state.me.info?.guide.id
      ? this.state.me.info?.guide.name
      : userId == this.state.me.info?.host.id
      ? this.state.me.info?.host.name
      : userId == this.state.me.info?.residence.id
      ? this.state.me.info?.residence.name
      : "";
  };

  addRemoteStreamDetails = (streamID: any, stream: any, details: any) => {
    const _stream = new AgoraStream(
      stream,
      streamID,
      false,
      false,
      false,
      details && details?.name ? details?.name : "",
      ""
    );
    this.addRTCUser(streamID);
    this.addRemoteStream(_stream);
  };

  updateCurrentMicrophone = (microphoneId: any) => {
    this.state = {
      ...this.state,
      currentMicrophone: microphoneId,
    };
    this.commit(this.state);
  };

  updateCurrentCamera = (cameraId: any) => {
    this.state = {
      ...this.state,
      currentCamera: cameraId,
    };
    this.commit(this.state);
  };

  updateCurrentSpeaker = (speakerId: any) => {
    this.state = {
      ...this.state,
      currentSpeaker: speakerId,
    };
    this.commit(this.state);
  };

  handleDeviceChange = () => {
    // Microphone change event hander
    AgoraRTC.onMicrophoneChanged = async (changedDevice: any) => {
      // When plugging out a device, switch to a device that is newly plugged in.
      if (changedDevice.state != "ACTIVE") {
        const oldMicrophones = await AgoraRTC.getMicrophones();
        if (oldMicrophones[0]) {
          if (
            typeof this.state.rtc.localStream?.stream.audioTrack !=
              "undefined" &&
            this.state.rtc.localStream?.stream.audioTrack != null
          ) {
            this.state.rtc.localStream?.stream.audioTrack.setDevice(
              oldMicrophones[0].deviceId
            );
            window.sessionStorage.setItem(
              "microphoneId",
              oldMicrophones[0].deviceId
            );
            this.updateCurrentMicrophone(oldMicrophones[0].deviceId);
          }
        }
      }
    };

    // Camera change event handler
    AgoraRTC.onCameraChanged = async (changedDevice: any) => {
      // When plugging out a device, switch to a device that is newly plugged in.
      if (changedDevice.state != "ACTIVE") {
        const oldCameras = await AgoraRTC.getCameras();
        if (oldCameras[0]) {
          if (
            typeof this.state.rtc.localStream?.stream.videoTrack !=
              "undefined" &&
            this.state.rtc.localStream?.stream.videoTrack != null
          ) {
            this.state.rtc.localStream?.stream.videoTrack.setDevice(
              oldCameras[0].deviceId
            );
            window.sessionStorage.setItem("cameraId", oldCameras[0].deviceId);
            this.updateCurrentCamera(oldCameras[0].deviceId);
          }
        }
      }
    };

    //Audio Playback change event handler
    AgoraRTC.onPlaybackDeviceChanged = async (changedDevice: any) => {
      // When plugging out a device, switch to a device that is newly plugged in.
      if (changedDevice.state != "ACTIVE") {
        const playBackDevices = await AgoraRTC.getPlaybackDevices();
        if (playBackDevices[0]) {
          if (
            typeof this.state.rtc.localStream?.stream.videoTrack !=
              "undefined" &&
            this.state.rtc.localStream?.stream.videoTrack != null
          ) {
            this.state.rtc.localStream?.stream.audioTrack.setPlaybackDevice(
              playBackDevices[0].deviceId
            );
            window.sessionStorage.setItem(
              "speakerId",
              playBackDevices[0].deviceId
            );
            this.updateCurrentSpeaker(playBackDevices[0].deviceId);
          }
        }
      }
    };
  };

  ExitFullScreenMode = () => {
    try {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        let rightSideElement = document.querySelector(
          "section.sidebar-container._right"
        ) as any;
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      return "success";
    } catch (err) {
      return "error";
    }
  };

  convertTimeFromUTC = (date: any) => {
    return new Date(date);
  };

  canStartVisit = (timeinUtc: any) => {
    const visit_date: any = this.convertTimeFromUTC(timeinUtc);
    const today: any = new Date();
    const differenceMinutes = (visit_date.getTime() - today.getTime()) / 60000;
    if (differenceMinutes < 10) {
      return true;
    } else {
      return false;
    }
  };

  fetchVisitDetails = async (id: any) => {
    const response = { status: 0, visit: "" };
    try {
      let options = {};
      if (Cookies.get("token")) {
        options = {
          headers: { Authorization: Cookies.get("token") },
        };
      }
      const res = await Axios.get(ApiUrls.visitDetailUrl(id), options);
      response.status = res?.status;
      response.visit = res?.data?.data;
      //return res?.data?.data;
    } catch (e: any) {
      response.status = e?.response?.status;
    }
    return response;
  };

  logout = () => {
    Cookies.remove("token");
    sessionStorage.clear();
    window.location.href = "/";
  };

  handleDeviceError = () => {
    toast.error(
      this.state.me.info.role == "guide"
        ? "Your audio or video device is not detected."
        : "Votre périphérique audio ou vidéo n'est pas détecté."
    );
  };

  getAgoraVideoProfile = async () => {
    if (this.state.me.info.role == "guide") {
      try {
        const res = await Axios.get(ApiUrls.agoraVideoResolution);
        if (res?.data?.data?.web) {
          return res?.data?.data?.web;
        } else {
          return "1080p_5";
        }
      } catch (e) {
        return "1080p_5";
      }
    } else {
      return "480p_1";
    }
  };

  getAgoraLowStreamProfile = () => {
    if (this.state.me.info.role == "guide") {
      return [640, 480, 15, 500];
    } else {
      return [640, 360, 15, 400];
    }
  };

  fetchGuideVisits = async (page: any, limit: any) => {
    const response = { status: 0, visits: [] };
    const token = Cookies.get("token");
    try {
      const res = await Axios.get(ApiUrls.guidelistUrl(page, limit), {
        headers: { Authorization: token },
      });
      response.status = res?.status;
      response.visits = res?.data?.data ? res?.data?.data : [];
    } catch (e: any) {
      response.status = e?.response?.status;
      if (!response.status) {
        if (e?.message?.includes("401")) {
          response.status = 401;
        }
      }
    }
    return response;
  };
}

export const eventRoomStore = new EventRoomStore();

// TODO: Please remove it before release in production
// 备注：请在正式发布时删除操作的window属性
//@ts-ignore
window.eventRoomStore = eventRoomStore;
