import React, { useEffect, useState } from "react";
import "./welcome.scss";
import { useHistory } from "react-router-dom";
import { formatTime, formatDay } from "@/utils/helper";
import WelcomeLoader from "../../WelcomeLoader/WelcomeLoader";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import LoginForm from "../../Login/LoginForm";
import { eventRoomStore } from "@/stores/event";
import HeaderGuide from "../Header";
import { isAndroid } from "react-device-detect";
import { globalStore } from "@/stores/global";

const GuideWelcome = (props: any) => {
  const [isLoader, setisLoader] = useState(false);
  if (!Cookies.get("token")) {
    return <LoginForm />;
  }
  const history = useHistory();
  const visit = props?.visit_id;
  const [GuideDetails, setGuideDetails] = useState<any>(props?.visit);

  const joinVisit = (time: any) => {
    if (eventRoomStore.canStartVisit(time)) {
      if (isAndroid) {
        history.push(`/handlenative/${visit}`);
      } else {
        history.push(`/meeting/${visit}`);
      }
    } else {
      toast.error("You are not Allowed to Join before 10 minutes");
    }
  };

  useEffect(() => {
    globalStore.stopLoading();
  }, []);

  return (
    <>
      <div className="guide-welcome">
        <HeaderGuide />
        {isLoader ? (
          <WelcomeLoader />
        ) : (
          <div className="guide-welcome-content">
            <div className="guide-welcome-content-name">
              Hello {GuideDetails.guide_name}
            </div>
            <div className="guide-welcome-content-header">
              Your{" "}
              {GuideDetails?.number_of_visits &&
                GuideDetails.number_of_visits.toString().toLowerCase() ==
                  "new" && <span>New </span>}{" "}
              Visit
            </div>
            <div className="guide-welcome-content-tour-place">
              {GuideDetails.visit_name}
            </div>
            <div className="guide-welcome-content-time">
              <div>
                {formatDay(
                  eventRoomStore.convertTimeFromUTC(GuideDetails.time_in_utc)
                )}{" "}
                /
                {formatTime(
                  eventRoomStore.convertTimeFromUTC(GuideDetails.time_in_utc)
                )}
              </div>
            </div>
            <div className="guide-welcome-content-residence">
              <span>Residence </span>
              {GuideDetails.residence_name}
              {` ${GuideDetails.residence_type}`}
              <div>
                {GuideDetails.city} ,{GuideDetails.postalCode}
              </div>
            </div>
            <button
              onClick={() => {
                joinVisit(GuideDetails.time_in_utc);
              }}
              className="guide-welcome-content-button"
            >
              ENTER THE VISIT
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default GuideWelcome;
