import React, { useEffect } from "react";
import { PageHeader } from "@/components/header/header";
import WelcomeLoader from "@/pages/WelcomeLoader/WelcomeLoader";
import Cookies from "js-cookie";
import { isAndroid } from "react-device-detect";
import { Redirect, useHistory } from "react-router-dom";

const NativeLoader = () => {
  const history = useHistory();
  useEffect(() => {
    if (!Cookies.get("token") || !isAndroid) {
      history.push("/");
    }
  }, []);

  return (
    <>
      <div className="waiting-screen">
        <PageHeader />
        <div className="waiting-screen-content">
          <div className="welcomeloader-header">Veuillez patienter...</div>
          <WelcomeLoader />
          <div className="welcomeloader-text">
            Votre voyage culturel démarre dans quelques instants.
          </div>
        </div>
      </div>
    </>
  );
};
export default NativeLoader;
