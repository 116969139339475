import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pics1 from "@/assets/pics1.png";
import Pics2 from "@/assets/pics2.jpg";
import Pics3 from "@/assets/pics3.jpg";
import Pics4 from "@/assets/pics4.jpg";
import Pics5 from "@/assets/pics5.jpg";
import Pics6 from "@/assets/pics6.png";
import Pics7 from "@/assets/pics7.jpg";
import Pics8 from "@/assets/pics8.jpg";
import Pics9 from "@/assets/pics9.png";
import Pics10 from "@/assets/pics10.png";
import Pics11 from "@/assets/pics11.jpg";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div>
        <img className="endscreen-image" src={Pics1} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics3} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics4} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics5} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics6} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics7} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics8} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics9} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics10} />
      </div>
      <div>
        <img className="endscreen-image" src={Pics11} />
      </div>
    </Slider>
  );
}
