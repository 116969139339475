import React from "react";
import { PageHeader } from "@/components/header/header";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import "./WaitingScreen.scss";

const WaitingRoom = () => {
  return (
    <>
      <div className="waiting-screen">
        <PageHeader />
        <div className="waiting-screen-content">
          <div className="welcomeloader-header">Veuillez patienter...</div>
          <WelcomeLoader />
          <div className="welcomeloader-text">
            Votre voyage culturel démarre dans quelques instants.
          </div>
        </div>
      </div>
    </>
  );
};
export default WaitingRoom;
