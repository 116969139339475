import { eventRoomStore } from "@/stores/event";
import AgoraRTMClient, { ChatCmdType } from "@/utils/agora-rtm-client";
import { jsonParse } from "@/utils/helper";
import { AgoraWebClient } from "@/utils/agora-web-client";

export default function useSubscribeRTMEvent() {
  const role = eventRoomStore.state.me.role;
  const subscribeRTMEvent = (rtmClient: AgoraRTMClient) => {
    rtmClient.on("error", (evt: any) => {});
    rtmClient.on("MemberCountUpdated", (count: number) => {
      eventRoomStore.updateViewerCount(count);
      if (role === 1) {
        eventRoomStore.getMembersList(eventRoomStore.state.me.channelName);
      }
    });

    rtmClient.on(
      "ChannelMessage",
      ({
        memberId,
        message,
      }: {
        message: { text: string };
        memberId: string;
      }) => {
        const { cmd, data } = jsonParse(message.text);
        if (cmd === ChatCmdType.chat) {
          const chatMessage = {
            text: data.text,
            ts: +Date.now(),
            id: memberId,
            name: data.name,
            profile_pic: data.profile_pic,
          };
          eventRoomStore.updateChatMessage(chatMessage);
        }

        if (cmd === ChatCmdType.muteAudio) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "audio");
        }
        if (cmd === ChatCmdType.unmuteAudio) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "audio"
          );
        }
        if (cmd === ChatCmdType.muteVideo) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "video");
        }
        if (cmd === ChatCmdType.unmuteVideo) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "video"
          );
        }
      }
    );

    rtmClient.on(
      "MessageFromPeer",
      ({ peerId, message }: { message: { text: string }; peerId: string }) => {
        const { cmd, data, msgData } = jsonParse(message.text);

        if (cmd === ChatCmdType.muteAudio) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "audio");
        }
        if (cmd === ChatCmdType.unmuteAudio) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "audio"
          );
        }
        if (cmd === ChatCmdType.muteVideo) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "video");
        }
        if (cmd === ChatCmdType.unmuteVideo) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "video"
          );
        }
      }
    );
  };
  return {
    subscribeRTMEvent,
  };
}
