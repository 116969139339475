import { useMemo } from "react";
import { AgoraMediaStream } from "@/utils/types";
import { useEventRoomState } from "@/containers/root-container";
import { ADMIN_UID } from "@/utils/config";
import { eventRoomStore } from "@/stores/event";

type StreamValue = {
  localStream: AgoraMediaStream;
  remoteStreams: AgoraMediaStream[];
  allStreams: AgoraMediaStream[];
};

export default function useStream() {
  const eventRoomState = useEventRoomState();
  const me = eventRoomState.me;
  const localStream = eventRoomState.rtc.localStream;
  const injectedStream = eventRoomState.rtc.injectedStream;
  const remoteStreams = useMemo(() => {
    const streamsArray: AgoraMediaStream[] = [];
    const remoteStreams = eventRoomState.rtc.remoteStreams;
    if (remoteStreams.size) {
      const remoteUsers = eventRoomState.rtc.users;
      for (let remoteUserId of remoteUsers) {
        if (remoteUserId === ADMIN_UID) continue;
        const stream = eventRoomState.rtc.remoteStreams.get(`${remoteUserId}`);
        if (stream) {
          // this is the special case when
          streamsArray.push(stream);
        }
      }
    }
    return streamsArray;
  }, [eventRoomStore.state.rtc.remoteStreams]);

  const guideStream = useMemo(() => {
    const stream = remoteStreams.find((value, index) => {
      if (value.streamID == me.info.guide.id) {
        return value;
      }
    });
    return stream;
  }, [remoteStreams]);

  const residenceStreams = useMemo(() => {
    const stream = remoteStreams.filter((value, index) => {
      if (value.streamID != me.info.guide.id) {
        return value;
      }
    });
    return stream;
  }, [remoteStreams]);

  const allStreams = useMemo(() => {
    const streams = [];
    if (eventRoomState.me.info.role == "guide") {
      if (injectedStream) {
        streams.push(injectedStream);
      }
      if (localStream) {
        streams.push(localStream);
      }
      streams.push(...residenceStreams);
    } else {
      if (injectedStream) {
        streams.push(injectedStream);
      }
      if (guideStream) {
        streams.push(guideStream);
      }
      streams.push(...residenceStreams);
      if (localStream) {
        streams.push(localStream);
      }
    }
    return streams;
  }, [
    eventRoomStore.state.rtc.localStream,
    eventRoomStore.state.rtc.remoteStreams,
    eventRoomStore.state.rtc.injectedStream,
  ]);

  // get admin stream
  const adminStream = useMemo(() => {
    if (eventRoomStore.state.rtc.remoteStreams.size) {
      return eventRoomState.rtc.remoteStreams.get(`${ADMIN_UID}`);
    }
    return null;
  }, [eventRoomStore.state.rtc.remoteStreams]);

  return {
    remoteStreams,
    localStream,
    allStreams,
    adminStream,
    guideStream,
  };
}
