import React, { useRef, useEffect } from "react";
import VideoPlayer from "@/components/video-player/video-player";
import useStream from "@/hooks/use-stream";
import "./show-talk-many-streams-masterclass.scss";
import { useEventRoomState } from "@/containers/root-container";
import { eventRoomStore } from "@/stores/event";

interface ShowTalkStreamsMultiMasterClassProps {
  liveStreams?: any;
}

const ShowTalkStreamsMultiMasterClass: React.FC<
  ShowTalkStreamsMultiMasterClassProps
> = ({ liveStreams = {} }) => {
  const eventRoomState = useEventRoomState();
  const streamsToPlayOnClick = useRef<any[]>([]);
  const { guideStream } = useStream();
  // add streams for which audio autoplay failed
  const handleFailedStreams = (stream: any) => {
    streamsToPlayOnClick.current.push(stream);
  };

  useEffect(() => {}, [liveStreams]);

  return (
    <>
      <div className={`large-class `}>
        {/* Main view - guide or Screen Share  */}
        {!eventRoomState.gridView && (
          <div className="main-stream-section">
            {/* show guide stream as main if present */}
            {liveStreams.mainStreams[0] !== undefined && (
              <div className="main-stream">
                <VideoPlayer
                  role=""
                  streamID={liveStreams.mainStreams[0].streamID}
                  stream={liveStreams.mainStreams[0].stream}
                  domId={`${liveStreams.mainStreams[0].streamID}`}
                  id={`${liveStreams.mainStreams[0].streamID}`}
                  video={
                    typeof liveStreams.mainStreams[0].stream.videoTrack ==
                      "undefined" ||
                    liveStreams.mainStreams[0].stream.videoTrack == null
                      ? false
                      : liveStreams.mainStreams[0].video
                  }
                  audio={
                    typeof liveStreams.mainStreams[0].stream.audioTrack ==
                      "undefined" ||
                    liveStreams.mainStreams[0].stream.audioTrack == null
                      ? false
                      : liveStreams.mainStreams[0].audio
                  }
                  local={liveStreams.mainStreams[0].local}
                  autoplay={true}
                  onAutoPlayFailed={handleFailedStreams}
                  mainSt={liveStreams.mainStreams[0]}
                  name={liveStreams.mainStreams[0].name}
                />
              </div>
            )}
            {!guideStream &&
              liveStreams.mainStreams.length === 0 &&
              eventRoomState.me.info.role != "guide" && (
                <div className="main-stream no-video">
                  <h4>
                    {eventRoomStore._state.me.info.role == "host"
                      ? "Guide is coming soon"
                      : "Votre guide personnel arrive prochainement"}
                  </h4>
                </div>
              )}
          </div>
        )}
        {/* Side stream - residences */}
        {liveStreams.injectedStreams.length === 0 &&
        eventRoomState.me.info.role === "guide" ? (
          <div id="side-way"></div>
        ) : (
          <>
            {eventRoomState.me.role !== 0 && (
              <div
                className={`side-stream-section hide-side-stream`}
                id="side-way"
              >
                {liveStreams.injectedStreams.length > 0 &&
                  liveStreams.injectedStreams.map(
                    (filteredStream: any, index: number) => {
                      return (
                        <div
                          className={`side-stream ${
                            filteredStream.streamID ==
                              eventRoomState.me.info.host.id &&
                            eventRoomState.me.info.role == "guide" &&
                            "hide"
                          }`}
                          data-step={filteredStream.videoTrack}
                          key={filteredStream.streamID}
                          data-id={filteredStream.streamID}
                        >
                          <VideoPlayer
                            role=""
                            streamID={filteredStream.streamID}
                            stream={filteredStream.stream}
                            domId={`${filteredStream.streamID}`}
                            id={`${filteredStream.streamID}`}
                            video={
                              typeof filteredStream.stream.videoTrack ==
                                "undefined" ||
                              filteredStream.stream.videoTrack == null
                                ? false
                                : filteredStream.video
                            }
                            audio={
                              typeof filteredStream.stream.audioTrack ==
                                "undefined" ||
                              filteredStream.stream.audioTrack == null
                                ? false
                                : filteredStream.audio
                            }
                            local={filteredStream.local}
                            autoplay={true}
                            onAutoPlayFailed={handleFailedStreams}
                            mainSt={liveStreams.mainStreams[0]}
                            name={filteredStream.name}
                            index={index}
                          />
                        </div>
                      );
                    }
                  )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ShowTalkStreamsMultiMasterClass;
