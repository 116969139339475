import React from "react";
import "./local-chat-control.scss";
import ChatIcon from "@mui/icons-material/Chat";

export default function LocalChatControl(props: any) {
  const { handleChat } = props;

  const handleChatClick = () => {
    handleChat();
  };

  return (
    <>
      <span className="chat-control" onClick={handleChatClick}>
        <div className="chat-control-icon">
          <span className="chat-control-position">
            <ChatIcon />
          </span>
        </div>
        <p className="icons-tip">Chat</p>
      </span>
    </>
  );
}
