import React from "react";

import {useGlobalState} from "../../containers/root-container";
import "./loading.scss";

export const Loading: React.FC<{}> = () => {
  return (
    <div className="loading-container">
      <a
        style={{background: 'url("../src/assets/loader.png")'}}
        href="#"
        className="intro-banner-vdo-play-btn bluebg"
        target="_blank"
      >
        <i
          className="glyphicon glyphicon-play whiteText"
          aria-hidden="true"
        ></i>
        <span className="ripple bluebg"></span>
        <span className="ripple bluebg"></span>
        <span className="ripple bluebg"></span>
      </a>
    </div>
  );
};

export default function LoadContainer() {
  const state = useGlobalState();
  const loading = state.loading;

  return loading ? <Loading /> : null;
}
