import React, { useEffect, useState, useRef } from "react";
import ShowTalkStreamsMultiMasterClass from "@/components/show-talk/show-talk-many-streams-masterclass/show-talk-many-streams-masterclass";
import useStream from "@/hooks/use-stream";
import "./pre-show.scss";
import ChatMenu from "@/components/chat-menu/chat-menu";
import PopUp from "@/components/popup/popup";
import Timer from "@/components/timer/timer";
import { eventRoomStore } from "@/stores/event";
import { useEventRoomState } from "@/containers/root-container";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HostOptions from "@/components/show-talk/show-talk-bottom-options/host-options/host-options";
import Loading from "@/components/loading/loading";
import NetworkIndicator from "@/components/network-indicator/network-indicator";
import { AgoraWebClient } from "@/utils/agora-web-client";
import ShowTalkBottomOptions from "@/components/show-talk/show-talk-bottom-options/show-talk-bottom-options";
import hostOptions from "@/components/show-talk/show-talk-bottom-options/host-options/host-options";
import classNames from "classnames";
import Logo from "@/assets/logo.png";
import PreCallScreen from "@/pages/precall/PrecallScreen";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";

const PreTalkShow = () => {
  const { allStreams, adminStream, guideStream } = useStream();
  const eventRoomState = useEventRoomState();
  const webClient = eventRoomStore.rtcClient as AgoraWebClient;
  const defaultStreams: any = { mainStreams: [], injectedStreams: [] };
  const [liveStreams, setLiveStreams] = useState(defaultStreams);
  const { initializeSocket, addSocketEvent, removeSocketEvent, emitEvent } =
    useSocket();

  useEffect(() => {
    let mainLiveStream;
    let mainLiveStreamID: any;
    if (!eventRoomState.gridView) {
      if (eventRoomStore.state.me.info.role === "guide") {
        const localStream = eventRoomState.rtc.localStream;
        mainLiveStreamID = localStream?.streamID;
        mainLiveStream = localStream;
      } else {
        mainLiveStreamID =
          eventRoomStore.state.me.mainLiveStreamID ||
          (allStreams.length && guideStream && guideStream.streamID) ||
          0;
        mainLiveStream = allStreams.find(
          (stream) => stream.streamID === mainLiveStreamID
        );
      }
      if (
        mainLiveStream &&
        eventRoomState.rtc.joined &&
        eventRoomState.isLive
      ) {
        webClient.setRemoteVideoStreamType(mainLiveStream.stream.streamID, 0);
        // update main stream id at root leven event store to be used other places
        eventRoomStore.setMeAttr("mainLiveStreamID", mainLiveStreamID);
      }
      const sideStreamsArray = allStreams.filter((stream) => {
        if (stream.streamID != mainLiveStreamID) {
          return true;
        }
        return false;
      });
      setLiveStreams({
        mainStreams: (mainLiveStream && [mainLiveStream]) || [],
        injectedStreams: sideStreamsArray,
      });
    } else {
      setLiveStreams({
        mainStreams: [],
        injectedStreams: allStreams,
      });
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
    }
  }, [allStreams, eventRoomState.gridView]);

  const [showChat, setShowChats] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showSideStream, setShowSideStream] = useState(false);
  const [fullscreenStream, setfullscreenStream] = useState(false);
  const handleShowPopUp = async () => {
    setShowPopUp((prevState) => !prevState);
  };

  const handleChatClick = (val = showChat) => {
    //setShowChats((prevState) => !prevState);
    setShowChats(!val);
  };

  useEffect(() => {
    initializeSocket();

    addSocketEvent(ESocketEvents.EndVisit, (visit_id) => {
      if (visit_id == eventRoomStore._state.me.channelName) {
        if (eventRoomStore._state.me.info.role == "guide") {
          window.location.href = "/end-visit";
        } else {
          window.location.href = "/end";
        }
      }
    });
    return () => {
      removeSocketEvent(ESocketEvents.EndVisit);
    };
  }, []);

  useEffect(() => {
    if (eventRoomState.hasStarted) {
      if (
        eventRoomState.me.info.role == "guide" ||
        eventRoomState.me.info.role == "host"
      ) {
        emitEvent(ESocketEvents.JoinVisit, {
          visit_id: eventRoomState.me.channelName,
          user_role: eventRoomState.me.info.role,
        });
        emitEvent(ESocketEvents.StartVisit, {
          visit_id: eventRoomState.me.channelName,
        });
      }

      if (
        eventRoomState.me.info.role == "host" &&
        sessionStorage.getItem("recording") == "true"
      ) {
        emitEvent(ESocketEvents.Record, {
          visit_id: eventRoomState.me.channelName,
          guide_id: eventRoomState.me.info.guide.id,
        });
      }

      if (eventRoomState.me.info.role == "residence") {
        emitEvent(ESocketEvents.JoinVisit, {
          visit_id: eventRoomState.me.channelName,
          user_role: eventRoomState.me.info.role,
        });
      }
    }
  }, [eventRoomState.hasStarted, eventRoomState.me.info.role]);

  return (
    <>
      <section
        className={classNames({
          _inner: true,
          "preshow-talk": true,
          "masterclass-container": true,
        })}
        id="preshow-talks"
      >
        <>
          {eventRoomState.hasStarted ? (
            <section
              className={`streams-container _left ${
                allStreams.length === 0 ? "flex-center" : ""
              }
                 ${"masterclass"} ${fullscreenStream && "Full-screen-hide"} `}
              id="main-streams"
            >
              <Loading />
              <div
                className={`top-bar ${showSideStream && "top-bar-absolute"} `}
              >
                <div className="top-bar-logo">
                  <img src={Logo} />
                </div>
                <div className="top-bar-time">
                  {(eventRoomStore._state.me.info.role == "guide" ||
                    eventRoomStore._state.me.info.role == "host") && <Timer />}
                  <NetworkIndicator />
                </div>
              </div>
              <div className={`stream-section`}>
                <ShowTalkStreamsMultiMasterClass liveStreams={liveStreams} />
              </div>
              {eventRoomState.isLive && eventRoomState.rtc.localStream && (
                <ShowTalkBottomOptions>
                  <HostOptions
                    stream={eventRoomState.rtc.localStream.stream}
                    audio={eventRoomState.rtc.localStream.audio || false}
                    video={eventRoomState.rtc.localStream.video || false}
                    fullScreenElemId="preshow-talks"
                    handleChat={handleChatClick}
                    handlePopUp={handleShowPopUp}
                    handlesideStream={setShowSideStream}
                    setfullscreenStream={setfullscreenStream}
                  />
                </ShowTalkBottomOptions>
              )}
              {eventRoomStore._state.isLive && showChat && (
                <section
                  className={`sidebar-container _right ${"float-chat-right"}`}
                >
                  <button
                    className="close-chat-mobile"
                    onClick={() => handleChatClick()}
                  >
                    <HighlightOffIcon />
                  </button>
                  <section className="chat-container">
                    <ChatMenu handleChat={handleChatClick} />
                  </section>
                </section>
              )}
              {showPopUp && <PopUp handlePopUpClose={handleShowPopUp} />}
            </section>
          ) : (
            <>
              <PreCallScreen
                JoinHandle={() => {
                  eventRoomStore.hasVisitStarted(true),
                    sessionStorage.setItem("visitStarted", "true");
                }}
              />
            </>
          )}
        </>
      </section>
    </>
  );
};

export default PreTalkShow;
