import EventEmitter from "events";
import AgoraRTC from "agora-rtc-sdk-ng";
import { eventRoomStore } from "@/stores/event";

export type DeviceInfo = {
  cameraCanUse: boolean;
  microphoneCanUse: boolean;
  devices: Device[];
};

export interface AgoraStreamSpec {
  streamID: number | string;
  video: boolean;
  audio: boolean;
  mirror?: boolean;
  screen?: boolean;
  microphoneId?: any;
  cameraId?: any;
  isInjectedStream?: boolean;
  audioOutput?: {
    volume: number;
    deviceId: string;
  };
  audioSource?: any;
  optimizationMode?: string;
}

const streamEvents: string[] = [
  "accessAllowed",
  "accessDenied",
  "track-ended",
  "videoTrackEnded",
  "audioTrackEnded",
  "player-status-changed",
];

const clientEvents: string[] = [
  //"stream-published",
  //"stream-added",
  "user-joined",
  "user-left",
  "user-info-updated",
  "user-published",
  "user-unpublished",
  //"stream-removed",
  //"stream-subscribed",
  //"peer-online",
  //"peer-leave",
  //"mute-audio",
  //"unmute-audio",
  //"mute-video",
  //"unmute-video",
  //"error",
  "network-type-changed",
  "network-quality",
  //"exception",
  //"onTokenPrivilegeWillExpire",
  //"onTokenPrivilegeDidExpire",
  //"streamInjectedStatus",
  //"inject-streaming-disconnected",
  //"network-quality"
];

export class AgoraRTCClient {
  private streamID: any;
  public _init: boolean = false;
  public _joined: boolean = false;
  public _published: boolean = false;
  private _internalTimer: NodeJS.Timeout | any;
  public _client: any = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
  public _bus: EventEmitter = new EventEmitter();
  public _localStream: any = null;
  public _localStreamVideoTrack: any = null;
  public _localStreamAudioTrack: any = null;
  public _streamEvents: string[];
  public _clientEvents: string[];
  public _addEventListener: boolean = false;

  constructor() {
    this.streamID = null;
    this._streamEvents = [];
    this._clientEvents = [];
  }

  destroyClient(): void {
    this.unsubscribeClientEvents();
  }

  subscribeClientEvents() {
    AgoraRTC.onAutoplayFailed = () => {
      eventRoomStore.updateAutoPlayFailedStatus(true);
    };

    if (this._addEventListener) return;
    this._addEventListener = true;
    for (let evtName of clientEvents) {
      this._clientEvents.push(evtName);
      this._client.on(evtName, (arg1: any, arg2: any) => {
        this._bus.emit(evtName, [arg1, arg2]);
      });
    }
  }

  unsubscribeClientEvents() {
    if (this._addEventListener) {
      for (let evtName of this._clientEvents) {
        this._client.off(evtName, () => {});
        this._clientEvents = this._clientEvents.filter(
          (it: any) => it === evtName
        );
      }
      this._addEventListener = false;
    }
  }

  subscribeLocalStreamEvents() {
    for (let evtName of streamEvents) {
      this._streamEvents.push(evtName);
      this._localStream.on(evtName, (args: any) => {
        this._bus.emit(evtName, args);
      });
    }
  }

  unsubscribeLocalStreamEvents() {
    if (this._localStream) {
      for (let evtName of this._streamEvents) {
        this._localStream.removeEventListener(evtName, (args: any[]) => {});
        this._streamEvents = this._streamEvents.filter(
          (it: any) => it === evtName
        );
      }
    }
  }

  renewToken(newToken: string) {
    if (!this._client)
      return console.warn(
        "renewToken is not permitted, checkout your instance"
      );
    this._client.renewToken(newToken);
  }

  removeAllListeners() {
    this.unsubscribeClientEvents();
    this._bus.removeAllListeners();
  }

  // subscribe
  on(evtName: string, cb: (args: any) => void) {
    this._bus.on(evtName, cb);
  }

  // unsubscribe
  off(evtName: string, cb: (args: any) => void) {
    this._bus.off(evtName, cb);
  }

  async publish() {
    return new Promise((resolve, reject) => {
      if (this._published) {
        return resolve("");
      }
      this._client.publish(this._localStream, (err: any) => {
        reject(err);
      });
      setTimeout(() => {
        resolve("");
        this._published = true;
      }, 300);
    });
  }

  async setClientRole(role: any) {
    this._client.setClientRole(role);
  }

  async unpublish(type = "") {
    return new Promise((resolve, reject) => {
      if (!this._published || !this._localStream) {
        return resolve("");
      }
      this._client.unpublish(this._localStream, (err: any) => {
        reject(err);
      });
      setTimeout(() => {
        resolve("");
        this.destroyLocalStream(type);
        this._published = false;
      }, 300);
    });
  }

  setRemoteVideoStreamType(stream: any, streamType: number) {
    this._client.setRemoteVideoStreamType(stream, streamType);
  }

  async enableDualStream() {
    return new Promise((resolve, reject) => {
      // this._client.enableDualStream(() => {
      //   const lowStreamParam = [320, 180, 15, 140];
      //   this._client.setLowStreamParameter({
      //     width: lowStreamParam[0],
      //     height: lowStreamParam[1],
      //     framerate: lowStreamParam[2],
      //     bitrate: lowStreamParam[3],
      //   });
      //   resolve();
      // }, reject);
      // const lowStreamParam = [320, 180, 15, 140];
      //const lowStreamParam = [640, 360, 15, 400];
      const lowStreamParam = eventRoomStore.getAgoraLowStreamProfile();
      try {
        this._client.setLowStreamParameter({
          width: lowStreamParam[0],
          height: lowStreamParam[1],
          framerate: lowStreamParam[2],
          bitrate: lowStreamParam[3],
        });
        this._client.enableDualStream();
        resolve("");
      } catch (e) {
        console.error(e);
        reject();
      }
    });
  }

  async createLocalStream(data: AgoraStreamSpec): Promise<any> {
    this._localStreamAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      microphoneId: data.microphoneId,
      AEC: true, // acoustic echo cancellation
      AGC: true, // audio gain control
      ANS: true, // automatic noise suppression
      encoderConfig: "speech_standard",
    });
    try {
      this._localStreamVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: await eventRoomStore.getAgoraVideoProfile(),
        optimizationMode: data.optimizationMode,
        cameraId: data.cameraId,
      });
    } catch (e) {
      eventRoomStore.handleDeviceError();
    }
    if (data.isInjectedStream) {
      this._localStream = [this._localStreamVideoTrack];
      return [null, this._localStreamVideoTrack];
    } else {
      this._localStream = [
        this._localStreamAudioTrack,
        this._localStreamVideoTrack,
      ];
      return this._localStream;
    }
    // this._localStream.setVideoEncoderConfiguration({
    //   resolution: {
    //     width: 1280,
    //     height: 720
    //   },
    //   frameRate: {
    //     min: 15,
    //     max: 30
    //   },
    //   bitrate: {
    //     min: 800,
    //     max: 2000
    //   }
    // });
    // return new Promise((resolve, reject) => {
    //   this._localStream.init(
    //     () => {
    //       this.streamID = data.streamID;
    //       this.subscribeLocalStreamEvents();
    //       if (data.audioOutput && data.audioOutput.deviceId) {
    //         this.setAudioOutput(data.audioOutput.deviceId)
    //           .then(() => {
    //           })
    //           .catch((err: any) => {
    //             console.warn("setAudioOutput failed", err, JSON.stringify(err));
    //           });
    //       }
    //       resolve(this._localStream);
    //     },
    //     (err: any) => {
    //       if ((err.msg === 'NotAllowedError' || err.msg === 'Error')) {
    //         alert('Permission not allowed')
    //       } else if (err.msg === 'NotFoundError') {
    //         alert('Could not find any media device')
    //       } else if (err.msg === 'NotReadableError') {
    //         alert('Hardware error occur, Please try refreshing the page or updating the device driver');
    //       }
    //       reject(err);
    //     }
    //   );
    // });
  }

  destroyLocalStream(type = "") {
    this.unsubscribeLocalStreamEvents();
    if (this._localStream) {
      // if (this._localStream.isPlaying()) {
      //   this._localStream.stop();
      // }
      // this._localStream.close();
      if (type == "screenShare") {
        if (Array.isArray(this._localStream)) {
          if (typeof this._localStream[0] != "undefined") {
            this._localStream[0].stop();
            this._localStream[0].close();
          }

          if (typeof this._localStream[1] != "undefined") {
            this._localStream[1].stop();
            this._localStream[1].close();
          }
        } else {
          this._localStream.stop();
          this._localStream.close();
        }

        if (this._localStream?.stream?.audioTrack.isPlaying) {
          this._localStream.stream.audioTrack.stop();
          this._localStream.stream.audioTrack.close();
        }
      } else {
        if (this._localStream?.stream?.videoTrack.isPlaying) {
          this._localStream.stream.videoTrack.stop();
          this._localStream.stream.videoTrack.close();
        }

        if (this._localStream?.stream?.audioTrack.isPlaying) {
          this._localStream.stream.audioTrack.stop();
          this._localStream.stream.audioTrack.close();
        }
      }
    }
    this._localStream = null;
    this.streamID = 0;
  }

  async join(appId: string, token?: string, channel?: string, uid?: number) {
    // return new Promise((resolve, reject) => {
    //   this._client.join(appId, token, channel, uid, resolve, reject);
    // });
    try {
      this.subscribeClientEvents();
      return await this._client.join(appId, channel, token, uid);
    } catch (e) {
      return;
    }
  }

  async leave() {
    if (this._client) {
      return new Promise((resolve, reject) => {
        this._client.leave(resolve, reject);
      });
    }
  }

  setAudioOutput(speakerId: string) {
    return new Promise((resolve, reject) => {
      if (this._client) {
        this._client.setAudioOutput(speakerId, resolve, reject);
        return;
      }
      resolve("");
    });
  }

  setAudioVolume(volume: number) {
    this._client.setAudioVolume(volume);
  }

  async subscribe(remoteUser: any, mediaType: any) {
    return await this._client.subscribe(remoteUser, mediaType);
  }

  destroy(type = ""): void {
    this._internalTimer && clearInterval(this._internalTimer);
    this._internalTimer = null;
    this.destroyLocalStream(type);
    this.removeAllListeners();
  }

  async exit() {
    try {
      await this.leave();
    } catch (err) {
      throw err;
    } finally {
      this.destroy();
    }
  }

  getDevices(): Promise<DeviceInfo> {
    return new Promise((resolve, reject) => {
      AgoraRTC.getDevices()
        .then((devices: any) => {
          const _devices: any[] = [];
          devices.forEach((item: any) => {
            _devices.push({
              deviceId: item.deviceId,
              kind: item.kind,
              label: item.label,
            });
          });
          resolve({
            cameraCanUse:
              devices.filter((it: any) => it.kind === "videoinput").length > 0
                ? true
                : false,
            microphoneCanUse:
              devices.filter((it: any) => it.kind === "audioinput").length > 0
                ? true
                : false,
            devices: _devices,
          });
        })
        .catch((err: any) => {
          reject(err);
        });
      // AgoraRTC.getDevices(
      //   (devices: any) => {
      //     const _devices: any[] = [];
      //     devices.forEach((item: any) => {
      //       _devices.push({
      //         deviceId: item.deviceId,
      //         kind: item.kind,
      //         label: item.label
      //       });
      //     });
      //     resolve({
      //       cameraCanUse:
      //         devices.filter((it: any) => it.kind === "videoinput").length > 0
      //           ? true
      //           : false,
      //       microphoneCanUse:
      //         devices.filter((it: any) => it.kind === "audioinput").length > 0
      //           ? true
      //           : false,
      //       devices: _devices
      //     });
      //   },
      //   (err: any) => {
      //     reject(err);
      //   }
      // );
    });
  }

  async forceGetDevices(): Promise<DeviceInfo> {
    let audioPermissionOK = null;
    let videoPermissionOK = null;
    let tempAudioStream = "" as any;
    let tempVideoStream = "" as any;
    try {
      tempAudioStream = await AgoraRTC.createMicrophoneAudioTrack();
    } catch (e) {
      audioPermissionOK = "";
    }

    try {
      tempVideoStream = await AgoraRTC.createCameraVideoTrack();
    } catch (e) {
      videoPermissionOK = "";
    }

    // const audioPermissionOK = new Promise(resolve => {
    //   tempAudioStream.init(
    //     () => resolve(null),
    //     (err: any) => resolve(err)
    //   );
    // });
    // const videoPermissionOK = new Promise(resolve => {
    //   tempVideoStream.init(
    //     () => resolve(null),
    //     (err: any) => resolve(err)
    //   );
    // });

    try {
      let [microphone, camera] = await Promise.all([
        audioPermissionOK,
        videoPermissionOK,
      ]);
      let result = await this.getDevices();

      if (microphone !== null) {
        result.microphoneCanUse = false;
        console.warn("create audio temp stream failed!", microphone);
      }
      if (camera !== null) {
        result.cameraCanUse = false;
        console.warn("create video temp stream failed!", camera);
      }

      return result;
    } catch (err) {
      throw err;
    } finally {
      if (tempAudioStream != "") tempAudioStream.close();
      if (tempVideoStream != "") tempVideoStream.close();
      // if (tempAudioStream.initialized) tempAudioStream.close();
      // if (tempVideoStream.initialized) tempVideoStream.close();
    }
  }
}
