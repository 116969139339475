import React, { useState, useEffect } from "react";
import "./Endscreen.scss";
import SimpleSlider from "./Carousel";
import { PageHeader } from "@/components/header/header";
import Footer from "../Footer/Footer";
import Cookies from "js-cookie";

const Endscreen = () => {
  /* Guide can't access residence/host's end screen page */
  if (Cookies.get("token")) {
    window.location.href = "/";
  }
  return (
    <div className="endscreen">
      <PageHeader ShowOffer={true} Showcontact={true} />
      <div className="endscreen-container">
        <div className="endscreen-header">
          Votre voyage culturel est terminé.
        </div>
        <div className="endscreen-text">A très bientôt </div>
        <div style={{ width: "100%" }}>
          <SimpleSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Endscreen;
