import { AgoraWebClient } from "@/utils/agora-web-client";
import { eventRoomStore } from "@/stores/event";
import { useEventRoomState } from "@/containers/root-container";

export default function useSubscribeRTCEvent() {
  const eventRoomState = useEventRoomState();
  const subscribeRTCEvent = (webClient: AgoraWebClient) => {
    webClient.rtc.on("user-joined", async ([remoteUser]) => {
      const streamID = remoteUser.uid;
      let tempRemoteStream = {
        audioTrack: null,
        videoTrack: null,
      } as any;

      const details = {
        name: eventRoomStore.getUserNameById(streamID),
      };

      eventRoomStore.addRemoteStreamDetails(
        streamID,
        tempRemoteStream,
        details
      );
    });

    webClient.rtc.on("user-left", ([user, reason]) => {
      handleStreamRemove(user.uid);
    });

    webClient.rtc.on("user-published", async ([remoteUser, mediaType]) => {
      const streamID = remoteUser.uid;
      //if (streamID !== eventRoomStore.state.me.uid) {
      await webClient.subscribe(remoteUser, mediaType);
      let streamTrack =
        mediaType === "video" ? remoteUser.videoTrack : remoteUser.audioTrack;
      let streamTrackType = mediaType === "video" ? "videoTrack" : "audioTrack";
      const details = {
        name: eventRoomStore.getUserNameById(streamID),
      };
      eventRoomStore.updateRemoteDetails(
        streamTrackType,
        streamID,
        streamTrack,
        details
      );
      //});
    });
    webClient.rtc.on("user-unpublished", ([remoteUser, mediaType]) => {
      eventRoomStore.mute(remoteUser.uid, mediaType);
    });
  };

  const handleStreamRemove = (streamID: number) => {
    const pinid = Number(sessionStorage.getItem("pinid"));
    if (
      eventRoomStore.state.rtc.injectedStream &&
      +streamID === +eventRoomStore.state.rtc.injectedStream.streamID
    ) {
      eventRoomStore.removeInjectedStream();
    } else {
      eventRoomStore.removePeerUser(streamID);
      eventRoomStore.removeRemoteStream(streamID);
    }
    if (streamID === eventRoomStore.state.me.mainLiveStreamID) {
      eventRoomStore.updateLocalMe({
        mainLiveStreamID: 0,
      });
    }

    if (streamID === pinid) {
      sessionStorage.setItem("pinid", "0");
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
    }
  };

  return {
    subscribeRTCEvent,
  };
}
