import React, { useRef, useEffect, useState } from "react";
import { eventRoomStore } from "@/stores/event";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import "./remote-audio-control.scss";
import { useEventRoomState } from "@/containers/root-container";
interface RemoteAudioControlProps {
  streamID?: any;
  audio: boolean;
}

const AudioMuteIcon = '<i class="fa fa-microphone-slash">Muted</i>';
const AudioUnMuteIcon = '<i class="fa fa-microphone">Unmuted</i>';

const RemoteAudioControl: React.FC<RemoteAudioControlProps> = ({
  streamID,
  audio,
}) => {
  const eventRoomState = useEventRoomState();
  const handleAudioClick = (evt: any) => {
    evt.stopPropagation();
    if (audio) {
      eventRoomStore.handleRemoteAudio(streamID, "mute");
    } else {
      eventRoomStore.handleRemoteAudio(streamID, "unmute");
    }
  };
  return (
    <span
      className="audio stream-control controller"
      onClick={handleAudioClick}
    >
      <span>
        {audio ? <MicIcon /> : <MicOffIcon />}
        {eventRoomState.me.info.role == "guide" ? (
          <p className="icons-tip">{audio ? "Mute" : "Unmute"}</p>
        ) : (
          <p className="icons-tip">{audio ? "Silence" : "Son"}</p>
        )}
      </span>
    </span>
  );
};

export default React.memo(RemoteAudioControl);
