import React, {useState, useEffect, useRef} from "react";
import "./show-talk-bottom-options.scss";
import { useEventRoomState } from "@/containers/root-container";

export default function ShowTalkBottomOptions({ children }: any) {
  const eventRoomState = useEventRoomState();

  return (
  <>
    <div className={`streambottomicons`}>
      {children}
    </div>
  </>
  );
}
