import React, { useEffect, useState } from "react";
import { eventRoomStore } from "@/stores/event";
import NoSignal from "../../assets/signal/no_sig.svg";
import signal1 from "../../assets/signal/1.svg";
import signal2 from "../../assets/signal/2.svg";
import signal3 from "../../assets/signal/3.svg";
import signal4 from "../../assets/signal/4.svg";
import signal5 from "../../assets/signal/5.svg";
import NetworkPopup from "./NetworkPopUp";

import "./network-indicator.scss";

const NetworkIndicator = () => {
  const [networkQuality, setNetworkQuality] = useState(0);
  useEffect(() => {
    const webClient = eventRoomStore.rtcClient;
    webClient.rtc.on("network-quality", ([stats]) => {
      let barStatus = 6 - parseInt(stats.downlinkNetworkQuality);
      if (!stats.downlinkNetworkQuality) {
        setNetworkQuality(0);
      } else {
        setNetworkQuality(barStatus);
      }
    });
    return () => {
      webClient.rtc.off("network-quality", () => {});
    };
  }, []);

  return (
    <>
      {networkQuality < 1 ? (
        <NetworkPopup />
      ) : (
        <div className="network-signal-container">
          {networkQuality == 0 && (
            <>
              <img src={NoSignal} />
            </>
          )}
          {networkQuality == 1 && (
            <>
              <img src={signal1} />
            </>
          )}
          {networkQuality == 2 && (
            <>
              <img src={signal2} />
            </>
          )}
          {networkQuality == 3 && (
            <>
              <img src={signal3} />
            </>
          )}
          {networkQuality == 4 && (
            <>
              <img src={signal4} />
            </>
          )}
          {networkQuality == 5 && (
            <>
              <img src={signal5} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(NetworkIndicator);
