import React from 'react';
import {BrowserRouter, Route, Router} from 'react-router-dom';

export const RouterContext = React.createContext({} as Router);

function CustomBrowserRouter ({children}: any) {
  return (
    <BrowserRouter>
      <Route>
        {(routeProps: any) => (
          <RouterContext.Provider value={routeProps}>
            {children}
          </RouterContext.Provider>
        )}
      </Route>
    </BrowserRouter>
  )
}

export default CustomBrowserRouter;