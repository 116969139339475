import React, { useState } from "react";
import "../../components/popup/popup.scss";

export default function PreCallScreenPopUp(props: any) {
  const getStyle = () => {
    return {
      width: "42%",
    };
  };
  const [showPopUp, setShowPopUp] = useState(true);

  return (
    <>
      {showPopUp && (
        <div id="myModal" className="modal" onClick={props.handlePopUpClose}>
          <div className={`modal-content`} style={getStyle()}>
            <div className="s-card-inner">
              <h5>
                Remember to put your phone in landscape format before joining
                the tour
              </h5>
              <div className="s-card-wrapper">
                <div className="s-card-info">
                  <div className="s-btn">
                    <button
                      className="s-btn-quit"
                      onClick={() => {
                        setShowPopUp(false);
                      }}
                    >
                      Sure
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
