export function genUid(): string {
  const id = +Date.now() % 1000000;
  return id.toString();
}

export function jsonParse(json: string) {
  try {
    return JSON.parse(json);
  } catch (err) {
    return {};
  }
}

export function resolvePeerMessage(text: string) {
  const body = jsonParse(text);
  return body;
}

export function formatDate(newDate: Date) {
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const d = newDate;
  const year = d.getFullYear() || "00";
  const date = d.getDate() || "00";
  const monthName = months[d.getMonth()] || "00";
  const dayName = days[d.getDay()];
  const formatted = `${date} ${monthName} ${year}`;
  return formatted.toString();
}

export function formatDay(newDate: Date) {
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  const days = [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ];
  const d = newDate;
  const year = d.getFullYear() || "00";
  const date = d.getDate() || "00";
  const monthName = months[d.getMonth()] || "00";
  const dayName = days[d.getDay()];
  const formatted = `${dayName} ${date} ${monthName} ${year}`;
  return formatted.toString();
}

function addZero(i: any) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
export function formatTime(newDate: Date) {
  const d = newDate;
  let h = addZero(d.getHours()) || "00";
  let m = addZero(d.getMinutes()) || "00";
  let time = h + "h" + m;
  return time;
}

export function CurrentTime(newDate: Date) {
  const d = newDate;
  let h = addZero(d.getHours()) || "00";
  let m = addZero(d.getMinutes()) || "00";
  // let s = addZero(d.getSeconds()) || "00";
  let time = h + ":" + m;
  return time;
}

export function abbreviateNumber(number: number) {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  // what tier? (determines SI symbol)
  const tier = (Math.log10(number) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

export const handleRegion = (region: string): number => {
  switch (region) {
    case "US_EAST_1":
      return 0;
    case "US_EAST_2":
      return 1;
    case "US_WEST_1":
      return 2;
    case "US_WEST_2":
      return 3;
    case "EU_WEST_1":
      return 4;
    case "EU_WEST_2":
      return 5;
    case "EU_WEST_3":
      return 6;
    case "EU_CENTRAL_1":
      return 7;
    case "AP_SOUTHEAST_1":
      return 8;
    case "AP_SOUTHEAST_2":
      return 9;
    case "AP_NORTHEAST_1":
      return 10;
    case "AP_NORTHEAST_2":
      return 11;
    case "SA_EAST_1":
      return 12;
    case "CA_CENTRAL_1":
      return 13;
    case "AP_SOUTH_1":
      return 14;
    case "CN_NORTH_1":
      return 15;
    case "US_GOV_WEST_1":
      return 17;
    case "oss-us-west-1":
      return 8;
    case "US_East_1":
      return 9;
    case "oss-us-east-1":
      return 9;
    case "AP_Southeast_1":
      return 10;
    case "oss-ap-southeast-1":
      return 10;
    case "AP_Southeast_2":
      return 11;
    case "oss-ap-southeast-2":
      return 11;
    case "AP_Southeast_3":
      return 12;
    case "oss-ap-southeast-3":
      return 12;
    case "AP_Southeast_5":
      return 13;
    case "oss-ap-southeast-5":
      return 13;
    case "AP_Northeast_1":
      return 14;
    case "oss-ap-northeast-1":
      return 14;
    case "AP_South_1":
      return 15;
    case "oss-ap-south-1":
      return 15;
    case "EU_Central_1":
      return 16;
    case "oss-eu-central-1":
      return 16;
    case "EU_West_1":
      return 17;
    case "oss-eu-west-1":
      return 17;
    case "EU_East_1":
      return 18;
    case "oss-me-east-1":
      return 18;
    default:
      return 0;
  }
};
