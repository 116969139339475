import React, { useState, useEffect, useRef } from "react";
import { ChannelParams, ChatCmdType } from "@/utils/agora-rtm-client";
import { useEventRoomState } from "@/containers/root-container";
import { eventRoomStore } from "@/stores/event";
import ThreeDotIcon from "@/assets/threedot.png";
import "emoji-mart/css/emoji-mart.css";
import { Picker, Emoji } from "emoji-mart";
import "./comment-section.scss";

const regexPattern = /^\s+/;
const truncateBlank: (m: string) => string = (message: string) =>
  message.replace(regexPattern, "");

export const CommentSection: React.FC<any> = ({ userDetails }) => {
  const eventRoomState = useEventRoomState();
  let { me, rtc } = eventRoomState;
  const [state, setState] = React.useState({
    comment: "",
  });

  const [showEmoji, setShowEmoji] = useState(false);
  const inputCommentRef: React.RefObject<HTMLInputElement> = React.createRef();
  const handleEmojiShow = () => {
    setShowEmoji((prevState) => !prevState);
  };
  const onEmojiClick = (emoji: any, event: any) => {
    let updatedComment = state.comment + emoji.native;
    setState({ comment: updatedComment.slice(0, 100) });
    inputCommentRef.current && inputCommentRef.current.focus();
  };

  function handleChange(evt: any) {
    setState({ comment: evt.target.value.slice(0, 100) });
  }

  const addCommentInChat = async (content: string) => {
    if (eventRoomStore.rtmClient && me.uid) {
      const msgBody: ChannelParams = {
        type: ChatCmdType.chat,
        msgData: {
          text: content,
          ts: +Date.now(),
          id: me.uid.toString(),
          name: rtc?.localStream?.name,
          profile_pic: me.info.profile_pic,
        },
      };
      await eventRoomStore.sendMessage(msgBody);
      setState({ comment: "" });
      setShowEmoji(false);
    }
  };
  const messageHandler = async () => {
    const val = truncateBlank(state.comment);
    val.length > 0 && (await addCommentInChat(val));
  };

  return (
    <>
      <div>
        <div className="comment-box">
          <input
            type="text"
            name="comment"
            placeholder="Comment"
            autoComplete="off"
            value={state.comment}
            onKeyPress={async (evt: any) => {
              if (evt.key === "Enter") {
                const val = truncateBlank(state.comment);
                val.length > 0 && (await addCommentInChat(val));
              }
            }}
            onChange={handleChange}
            ref={inputCommentRef}
          />
          <img src={ThreeDotIcon} onClick={handleEmojiShow} />
          <div
            className="emoji-picker"
            style={showEmoji ? { display: "flex" } : { display: "none" }}
          >
            <Picker
              onClick={onEmojiClick}
              showPreview={false}
              style={{ position: "absolute", bottom: "40px", right: "20px" }}
            />
          </div>
          <div className="comment-sent">
            <button className="comment-section-button" onClick={messageHandler}>
              {eventRoomState.me.info.role != "guide" ? (
                <span>Envoyer</span>
              ) : (
                <span>Send</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
