import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <>
      <div style={{ backgroundColor: "black", height: "20%" }}>
        <div id="footer-tm">
          <div>
            <img src="https://travelme-paris.com/wp-content/uploads/2021/03/Logo-Travel-Me-Footer.svg" />
          </div>
          <div>
            <h2>NOUS DÉCOUVRIR</h2>
            <a href="https://travelme-paris.com/en/choose-an-experience/">
              Choisir une experience
            </a>
            <a href="https://travelme-paris.com/senior-et-sante/">
              L'offre Senior
            </a>
          </div>
          <div>
            <h2>NOUS SUIVRE</h2>
            <div>
              <a
                href="https://www.instagram.com/travelmeparis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://travelme-paris.com/wp-content/uploads/2021/03/Picto-Instagram.png" />{" "}
                Instagram
              </a>
              <a
                href="https://www.linkedin.com/company/travel-me-paris/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://travelme-paris.com/wp-content/uploads/2021/03/Picto-linkedin.png" />
                Linkedin
              </a>
            </div>
          </div>
          <div>
            <a href="https://travelme-paris.com/questions-frequentes/">
              <h2>NOUS CONTACTER</h2>
            </a>
            <a href="tel:0952097337">
              <img src="https://travelme-paris.com/wp-content/uploads/2021/03/Picto-phone.png" />
              +33 (0)9 52 09 73 37
            </a>
            <a href="mailto:contact@travelme-paris.com">
              <img src="https://travelme-paris.com/wp-content/uploads/2021/03/Picto-mail.png" />
              contact@travelme-paris.com
            </a>
          </div>
          <div>
            <a href="https://travelme-paris.com/sinscrire-a-la-newsletter">
              <h2>S’inscrire à la newsletter</h2>
            </a>
            <a href="https://travelme-paris.com/mentions-legales/">
              <img src="https://travelme-paris.com/wp-content/uploads/2021/03/fleche-rose.png" />
              Mentions légales et confidentialité
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
