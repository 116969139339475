
import {Subject} from 'rxjs';
import {Map} from "immutable";

export  interface toastInterface {
  type: string
  message: string
  config?: object
}
export type GlobalState = {
  loading: boolean
  toastContainer: Map<string, toastInterface>;
  buttonLoading: boolean
}

export class Root {
  private subject: Subject<GlobalState> | null;
  public state: GlobalState;
  public readonly defaultState: GlobalState = {
    loading: false,
    toastContainer: Map<string, toastInterface>(),
    buttonLoading: false
  }

  constructor() {
    this.subject = null;
    this.state = this.defaultState;
  }

  initialize() {
    this.subject = new Subject<GlobalState>();
    this.state = {
      ...this.defaultState,
    }
    this.subject.next(this.state);
  }

  subscribe(updateState: any) {
    this.initialize();
    this.subject && this.subject.subscribe(updateState);
  }

  unsubscribe() {
    this.subject && this.subject.unsubscribe();
    this.subject = null;
  }

  commit (state: GlobalState) {
    this.subject && this.subject.next(state);
  }

  updateState(rootState: GlobalState) {
    this.state = {
      ...this.state,
      ...rootState,
    }
    this.commit(this.state);
  }



  showToast({ type, message, config = {} }: { type: string, message: string, config?:object }) {
    // check if this type already exist
    if (this.state.toastContainer.has(type)) return;
    const prevState = this.state;
    this.state = {
      ...this.state,
      toastContainer: prevState.toastContainer.set(`${type}`, { type, message, config })
    }
    this.commit(this.state);
  }

  hideToast(type: string) {
    const prevState = this.state;
    this.state = {
      ...this.state,
      toastContainer: prevState.toastContainer.delete(`${type}`)
    }
    this.commit(this.state);
  }


  showLoading () {
    this.state = {
      ...this.state,
      loading: true
    }
    this.commit(this.state);
  }

  stopLoading () {
    this.state = {
      ...this.state,
      loading: false
    }
    this.commit(this.state);
  }

  showButtonLoading () {
    this.state = {
      ...this.state,
      buttonLoading: true
    }
    this.commit(this.state);
  }

  stopButtonLoading () {
    this.state = {
      ...this.state,
      buttonLoading: false
    }
    this.commit(this.state);
  }

}

export const globalStore = new Root();

// @ts-ignore
window.globalStore = globalStore;