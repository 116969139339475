import React, { useEffect, useState } from "react";
import "./fullscreen.scss";
import { eventRoomStore } from "@/stores/event";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useEventRoomState } from "@/containers/root-container";

interface FullScreenProps {
  elemId?: string;
  handlesideStream?: any;
  setfullscreenStream?: any;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

const FullScreen: React.FC<FullScreenProps> = ({
  elemId,
  handlesideStream,
  setfullscreenStream,
}) => {
  const [screenMode, setScreenMode] = useState("exit");
  const eventRoomState = useEventRoomState();

  // registering full screen change event listener
  useEffect(() => {
    /* Standard syntax */
    document.addEventListener("fullscreenchange", handleESCKeyPress);

    /* Firefox */
    document.addEventListener("mozfullscreenchange", handleESCKeyPress);

    /* Chrome, Safari and Opera */
    document.addEventListener("webkitfullscreenchange", handleESCKeyPress);

    /* IE / Edge */
    document.addEventListener("msfullscreenchange", handleESCKeyPress);

    return () => {
      document.removeEventListener("fullscreenchange", handleESCKeyPress);
      document.removeEventListener("mozfullscreenchange", handleESCKeyPress);
      document.removeEventListener("webkitfullscreenchange", handleESCKeyPress);
      document.removeEventListener("msfullscreenchange", handleESCKeyPress);
    };
  }, []);

  const onFullScreenClick = (action: string) => {
    action === "enter" ? goToFullScreenMode() : ExitFullScreenMode();
  };

  const goToFullScreenMode = () => {
    const elem =
      (elemId && document.getElementById(elemId)) || document.documentElement;
    const isChromeOniPad =
      /CriOS/.test(navigator.userAgent) && /iPad/.test(navigator.userAgent);
    try {
      if (isChromeOniPad) {
        setScreenMode((prevState) =>
          prevState === "enter" ? "exit" : "enter"
        );
        setfullscreenStream(true);
      } else if (elem.requestFullscreen) {
        elem.requestFullscreen();
        setScreenMode((prevState) =>
          prevState === "enter" ? "exit" : "enter"
        );
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
        setScreenMode((prevState) =>
          prevState === "enter" ? "exit" : "enter"
        );
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
        setScreenMode((prevState) =>
          prevState === "enter" ? "exit" : "enter"
        );
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
        setScreenMode((prevState) =>
          prevState === "enter" ? "exit" : "enter"
        );
      }
    } catch (err) {}
    handlesideStream(true);
  };

  const ExitFullScreenMode = () => {
    try {
      let resp = eventRoomStore.ExitFullScreenMode();
      if (resp == "success") {
        setScreenMode((prevState) =>
          prevState === "enter" ? "exit" : "enter"
        );
      }
    } catch (err) {}
    setfullscreenStream(false);
    handlesideStream(false);
  };

  const handleESCKeyPress = () => {
    let mainDiv = document.querySelector(".preshow-talk") as any;
    if (mainDiv.classList.contains("full-screen")) {
      handlesideStream(false);
    } else {
      handlesideStream(false);
    }

    mainDiv.classList.toggle("full-screen");
    let rightSideElement = document.querySelector(
      "section.sidebar-container._right"
    ) as any;
    if (rightSideElement != null) {
      rightSideElement.classList.toggle("float-chat-right");
    }

    // setScreenMode((prevState) => (prevState === "enter" ? "exit" : "enter"));
  };

  return (
    <div
      className="expand"
      onClick={() =>
        onFullScreenClick(screenMode === "enter" ? "exit" : "enter")
      }
    >
      <span className="expand-icon">
        <div className="expand-position">
          {screenMode === "enter" ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </div>
      </span>
      {eventRoomState.me.info.role == "guide" ? (
        <p className="icons-tip">
          {screenMode === "enter" ? "Exit Full Screen" : "Full Screen"}
        </p>
      ) : (
        <p className="icons-tip">
          {screenMode === "enter" ? "Réduire l'écran" : "Plein écran"}
        </p>
      )}
    </div>
  );
};

export default React.memo(FullScreen);
