import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { updateUserToken } from "@/utils/api/services/auth.services";
import WelcomeLoader from "../../WelcomeLoader/WelcomeLoader";
import { eventRoomStore } from "../../../stores/event";
import { toast } from "react-toastify";
import { PageHeader } from "@/components/header/header";
import Footer from "../../Footer/Footer";
import { ApiUrls } from "@/utils/api/constant/api.constant";
import "../../EndScreen/Endscreen.scss";
import VisitTable from "../Visits/Table";

const EndScreen = () => {
  /* Restrict other user's to access guide's end screen page */
  if (!Cookies.get("token")) {
    window.location.href = "/";
  }
  const [isLoading, setIsLoading] = useState(true);
  const [visits, setVisits] = useState([]);
  const [currentpage, setcurrentpage] = useState(1);
  const itemsPerPage = 4;
  const totalevents: any = useRef(0);

  const fetchGuidesVisitsList = async (currentpage: any, itemsPerPage: any) => {
    try {
      const lists = await eventRoomStore.fetchGuideVisits(
        currentpage,
        itemsPerPage
      );
      if (lists.status == 200) {
        const visitData: any = lists.visits;
        if (currentpage == 1) {
          totalevents.current = visitData.total_visits;
        }
        setIsLoading(false);
        setVisits(visitData.entries);
      } else if (lists.status == 401) {
        await updateUserToken(Cookies.get("token"));
        fetchGuidesVisitsList(currentpage, itemsPerPage);
      } else {
        eventRoomStore.logout();
      }
    } catch (error) {
      toast.error("Sorry, something went wrong!!");
    }
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      setIsLoading(true);
      fetchGuidesVisitsList(currentpage, itemsPerPage);
    } else {
      window.location.href = "/";
    }
  }, [currentpage]);

  const feedbackHandler = () => {
    window.location.href = `${ApiUrls.feedbackUrl}`;
  };

  return (
    <>
      <PageHeader ShowOffer={false} Showcontact={true} />
      <div className="endscreen">
        {isLoading ? (
          <WelcomeLoader />
        ) : (
          <div className="endscreen-scheduling-events">
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "Roboto",
              }}
            >
              Your next scheduled events
            </p>
            <div className="endscreen-listing-table-height">
              {visits.length > 0 ? (
                <VisitTable
                  visits={visits}
                  itemsPerPage={itemsPerPage}
                  totalevents={totalevents.current}
                  currentpage={currentpage}
                  setcurrentpage={setcurrentpage}
                />
              ) : (
                <div className="endscreen-listing-table-no-response">
                  <div className="endscreen-listing-table-no-response-text">
                    No Visit Planned
                  </div>
                  <button
                    onClick={eventRoomStore.logout}
                    className="endscreen-listing-table-no-response-button"
                  >
                    LOGOUT
                  </button>
                </div>
              )}
            </div>
            <div className="endscreen-feedback">
              <p className="endscreen-feedback-para">
                Please feel free to share your feedback on this visit or contact
                us for any information
              </p>
              <button
                onClick={feedbackHandler}
                className="endscreen-feedback-button"
              >
                SEND US FEEDBACK
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default EndScreen;
