export class ApiUrls {
  static TRAVEL_ME_URL = process.env.REACT_APP_TRAVEL_ME_PARIS_URL;
  static TRAVEL_ME_CONTACT = ApiUrls.TRAVEL_ME_URL + "questions-frequentes";
  static domain = process.env.REACT_APP_BASE_URL;
  static baseUrl = ApiUrls.domain + "api/";
  static loginUrl = ApiUrls.baseUrl + "login";
  static refreshTokenUrl = ApiUrls.baseUrl + "jwt/token";
  static guidelistUrl = (page: any, limit: any) =>
    ApiUrls.baseUrl + `visits?limit=${limit}` + `&page=${page}`;
  static visitDetailUrl = (id: any) =>
    ApiUrls.baseUrl + "visits/details?visit=" + id;
  static AgoraUrl = ApiUrls.baseUrl + "agora/generate-token";
  static feedbackUrl = process.env.REACT_APP_FEEDBACK_SHEET_URL;
  static agoraVideoResolution = ApiUrls.baseUrl + "agora/video-resolution";
}
