import React from "react";
import "./privacy-policy.scss";
import { PageHeader } from "@/components/header/header";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <PageHeader ShowOffer={true} Showcontact={true} />
      <div className="privacy-policy-container">
        <div className="privacy-policy-content">
          <h1>Privacy policy</h1>
          <div className="policy">
            <p>
              Travel Me is a online travel platform that offers a range of
              remove cultural travel experiences. To provide you with the full
              range of Travel Me products and services, we need to collect and
              process information about you. The Privacy Policy and our Cookies
              Policy apply to all users of our Website and the Services. This
              includes where you visit or use our online services and we collect
              your personal information. These include: Travel Me website at
              www.travelme-paris.com and any webpage, subdomain, subpart and
              variant of that website; It also relates to our use of any
              personal information you provide to us in person, by phone, in
              letters, email and other correspondence. For the purposes of this
              Privacy Policy, the data controller is Travel Me SAS SAS with a
              capital of 1000 €, Registration: RCS Nanterre 894 072 396
              Headquarters Offices: Travel Me, 153, rue du Château, 75014 Paris
              Represented by Mrs. Sandra Giacomoni as President
            </p>
            <p>
              The servers of our plateform &nbsp;
              <a href={`${process.env.REACT_APP_TRAVEL_ME_VISIT_URL}`}>
                live.travelme-paris.com
              </a>
              &nbsp;are hosted in France by the company: OVH – 2 rue Kellermann
              – 59100 Roubaix – RCS Lille Métropole 424 761 419 00045 test
              January 6
            </p>
            <h4>Personal data policy.</h4>
            <p>
              When you communicate with Travel Me we collect information about
              your communication and any additional information you choose to
              provide. You may also send us information when you fill in a form,
              update or add information to your Travel Me account, respond to
              surveys, participate in promotions, communicate with our team,
              share your experiences with us or use other features of the
              Website.
            </p>
            <p>
              Personal data is protected in particular by law in France n °
              78-87 of January 6, 1978, law n ° 2004-801 of August 6, 2004,
              article L. 226-13 of the Penal Code and the European Directive of
              October 24, 1995.
            </p>
            <p>
              On the occasion of the use of the site live.travelme-paris.com may
              be collected: the URL of the links through which the user accessed
              the site live.travelme-paris.com the access provider user, the
              user’s Internet Protocol (IP) address and the necessary data to
              process the order.
            </p>
            <p>
              In any case Travel Me SAS only collects personal information about
              the user for the need of certain services offered by the site
              live.travelme-paris.com The user provides this information with
              full knowledge of the facts, in particular when ‘he proceeds by
              himself to their seizure. The user of the site is then informed of
              the obligation or not to provide this information.
            </p>
            <p>
              In accordance with the provisions of articles 38 and following of
              the law 78-17 of January 6, 1978 relating to data processing,
              files and freedoms, any user has a right of access, rectification
              and opposition to personal data. concerning him, by making his
              written and signed request, accompanied by a copy of the identity
              document with signature of the holder of the document, specifying
              the address to which the reply must be sent. Disclosure of your
              information Travel Me will not pass on your personal data to third
              parties except as set out in this Privacy Policy or with your
              consent No personal information of the user of the
              live.travelme-paris.com site is published without the user’s
              knowledge, exchanged, transferred, assigned or sold on any medium
              whatsoever to third parties.
            </p>
            <p>
              The databases are protected by the provisions of the law of July
              1, 1998 transposing Directive 96/9 of March 11, 1996 on the legal
              protection of databases.
            </p>
            <h4>General service information - Intellectual property</h4>
            <p>
              The user of the site &nbsp;
              <a
                href={`${process.env.REACT_APP_TRAVEL_ME_PARIS_URL}`}
                target="_blank"
              >
                www.travelme-paris.com
              </a>
              &nbsp; and any webpage, subdomain, subpart and variant of that
              website including live.travelme-paris.com is prohibited from
              reproducing and / or using the brands and logos present on the
              site without the prior written authorization of TRAVEL ME SAS.
            </p>
            <p>
              The user of the plateform is prohibited from copying, modifying,
              reproducing, translating, selling, publishing, exploiting and
              disseminating in a digital or other format, all or part of the
              information, texts, photos, images, videos and data on the site,
              which constitute works within the meaning of the provisions of
              Article L112-1 of the Intellectual Property Code. Violation of
              these mandatory provisions subjects the offender and all persons
              involved to the criminal and civil penalties provided for by law.
            </p>
            <p>
              TRAVEL ME SAS grants the user of the site a private,
              non-collective and non-exclusive right of use over its content.
              This right is limited to the right to print and / or save site
              content for exclusively personal use.
            </p>
            <p>
              Any use, redistribution of all or part of the content of the site,
              in a professional or commercial context, to third parties, is
              strictly prohibited without the prior written consent of TRAVEL ME
              SAS.
            </p>
            <p>
              Any recording, use, redistribution of all or part of online
              experiences, whether in a private or professional context with
              third parties, is strictly prohibited without the prior written
              consent of TRAVEL ME SAS.
            </p>
            <h4>Contact</h4>
            <h4>TRAVEL ME</h4>
            <p>153 rue du Château – 75014 Paris FRANCE</p>
            <a href={`mailto:${process.env.REACT_APP_TRAVEL_ME_EMAIL}`}>
              {process.env.REACT_APP_TRAVEL_ME_EMAIL}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
