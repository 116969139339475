import React, { useState, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { eventRoomStore } from "@/stores/event";
import { useEventRoomState } from "@/containers/root-container";
import { AgoraWebClient } from "@/utils/agora-web-client";
import "./preview-stream-video.scss";

export default function PreviewStreamVideo(props: any) {
  const eventRoomState = useEventRoomState();
  const [cameraDevices, setCameraDevices] = useState<any>([]);
  const [selectedCam, setSelectedCam] = useState<any>();
  const [tmpStream, setTmpStream] = useState<any>();

  const getDevicesList = async () => {
    const webClient = eventRoomStore.rtcClient as AgoraWebClient;
    let devices = await webClient.getDevices();
    const camDevices = devices.filter((x: any) => x.kind === "videoinput");
    setCameraDevices(camDevices);
  };

  useEffect(() => {
    async function setTmpPrevStream() {
      await getDevicesList();
      const localStream = eventRoomState.rtc.localStream as any;
      const selectedCameraId = localStream.stream.videoTrack._config.cameraId;
      try {
        const videoTrack = await AgoraRTC.createCameraVideoTrack({
          optimizationMode: "motion",
          cameraId: selectedCameraId,
        });
        setTmpStream({ videoTrack });
      } catch (e) {
        eventRoomStore.handleDeviceError();
      }
    }
    setTmpPrevStream();
  }, [AgoraRTC.getDevices]);

  useEffect(() => {
    async function updateDevicesList() {
      await getDevicesList();
    }
    if (typeof tmpStream != "undefined") {
      if (
        typeof tmpStream.videoTrack != "undefined" &&
        tmpStream.videoTrack != null
      ) {
        tmpStream.videoTrack.setDevice(eventRoomState.currentCamera);
        updateDevicesList();
        setCameraDevice(eventRoomState.currentCamera);
      }
    }
  }, [eventRoomState.currentCamera]);

  useEffect(() => {
    if (typeof tmpStream != "undefined") {
      tmpStream.videoTrack.play("local-preview");
    }
    return () => {
      if (typeof tmpStream != "undefined") {
        if (
          typeof tmpStream.videoTrack != "undefined" &&
          tmpStream.videoTrack != null
        ) {
          tmpStream.videoTrack.stop();
          tmpStream.videoTrack.close();
        }
      }
    };
  }, [tmpStream]);

  useEffect(() => {
    if (
      eventRoomStore._state.rtc.localStream != null &&
      eventRoomStore._state.rtc.localStream.stream.videoTrack._config.cameraId
    ) {
      setSelectedCam(
        eventRoomStore._state.rtc.localStream.stream.videoTrack._config.cameraId
      );
    }
  }, [eventRoomStore._state.rtc.localStream]);

  const setCameraDevice = (e: any) => {
    tmpStream.videoTrack.setDevice(e.target.value);
    setSelectedCam(e.target.value);
  };

  const setLocalStreamDevices = () => {
    const localStream = eventRoomState.rtc.localStream as any;
    if (typeof selectedCam != "undefined" && selectedCam != "") {
      localStream.stream.videoTrack.setDevice(selectedCam);
      window.sessionStorage.setItem("cameraId", selectedCam);
    }
    props.handlePreviewStream();
  };

  return (
    <>
      <div className="video-setting-popup">
        <div className="video-setting-container">
          <div className="video-setting-heading">
            <h1>CAMERA</h1>
          </div>
          <select
            onChange={setCameraDevice}
            value={selectedCam}
            className="video-setting-camera-select video-setting-select-box"
          >
            {cameraDevices.map((device: any, index: any) => (
              <option
                value={device.deviceId}
                key={index}
                onSelect={setCameraDevice}
              >
                {device.label}
              </option>
            ))}
          </select>
          <div id="local-preview" className="local-stream-preview"></div>
        </div>

        <div className="video-setting-footer">
          <div
            className="video-setting-footer__cancel"
            onClick={props.handlePreviewStream}
          >
            Cancel
          </div>
          <div
            className="video-setting-footer__apply"
            onClick={setLocalStreamDevices}
          >
            Ok
          </div>
        </div>
      </div>
    </>
  );
}
