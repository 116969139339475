import React, { useState, useRef } from "react";
import "./chat-menu.scss";
import { CommentSection } from "@/components/comment-section/comment-section";
import ChatMessage from "@/components/chat-message/chat-message";

export default function ChatMenu(props: any) {

  return (
    <>
      <div className="chat-menu">
        <div className={`item  chat-menu-active`}>Messages</div>
      </div>
      <ChatMessage />
      <CommentSection />
    </>
  );
}
