import React, { useEffect, useState } from "react";
import Place from "@/assets/place.png";
import Guide from "@/assets/guide.png";
import "./welcome.scss";
import { PageHeader } from "@/components/header/header";
import { useHistory } from "react-router-dom";
import { formatDay, formatTime } from "@/utils/helper";
import Footer from "../Footer/Footer";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import WaitingRoom from "../WaitingRoom/Waitingroom";
import { toast } from "react-toastify";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import { eventRoomStore } from "../../stores/event";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { globalStore } from "@/stores/global";

const Welcome = (props: any) => {
  globalStore.stopLoading();
  const { addSocketEvent, initializeSocket, emitEvent } = useSocket();
  const history = useHistory();
  const eventDetail = props?.visit as any;
  const visit = props?.visit_id;
  const eventid = eventDetail?.id;
  const user_type = eventDetail?.user_role;
  const [visitEnabled, setVisitEnabled] = useState(
    eventDetail?.visit_started ? eventDetail?.visit_started : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setwaiting] = useState(false);
  const JoinHandler = (time: any) => {
    if (user_type == "host") {
      if (eventRoomStore.canStartVisit(time)) {
        history.push(`/meeting/${visit}`);
      } else {
        toast.error("Vous n'êtes pas autorisé à rejoindre avant 10 minutes");
      }
    } else {
      if (visitEnabled) {
        //setIsLoading(false);
        setwaiting(true);
        setTimeout(() => {
          history.push(`/meeting/${visit}`);
        }, 10000);
      } else {
        toast.error(
          "Vous ne pouvez pas rejoindre votre voyage culturel pour le moment"
        );
      }
    }
  };

  const ImageHandler = (link: any) => {
    let text = link;
    text = text.replace("file/d/", "uc?id=");
    text = text.split("/view?usp=");
    return text[0];
  };

  useEffect(() => {
    //VisitDetails(eventid);
    initializeSocket();
    emitEvent(ESocketEvents.JoinVisit, {
      visit_id: eventid,
      user_role: user_type,
    });
    addSocketEvent(ESocketEvents.StartVisit, (visitId) => {
      if (eventid == visitId) {
        !visitEnabled ? setVisitEnabled(true) : null;
      }
    });
  }, []);

  return (
    <>
      {isWaiting ? (
        <WaitingRoom />
      ) : (
        <div className="welcome">
          <PageHeader ShowOffer={true} Showcontact={true} />
          <div className="welcome-page">
            {isLoading ? (
              <WelcomeLoader />
            ) : (
              <>
                <div className="welcome-header">
                  <div className="welcome-header-text">Votre Vonage</div>
                  <p className="welcome-placename">{eventDetail.visit_name}</p>
                </div>
                <div className="welcome-content">
                  <div className="welcome-prescreen-absolute">
                    <div className="welcome-prescreen">
                      <div className="welcome-image">
                        <div className="welcome-image-name">
                          {eventDetail.guide_name}
                        </div>
                        <div className="welcome-image-place">
                          <img
                            src={
                              eventDetail?.visit_picture &&
                              eventDetail?.visit_picture.includes("http")
                                ? ImageHandler(eventDetail.visit_picture)
                                : Place
                            }
                          />
                        </div>
                        <div className="avatar">
                          <img
                            src={
                              eventDetail?.guide_picture &&
                              eventDetail?.guide_picture.includes("http")
                                ? ImageHandler(eventDetail.guide_picture)
                                : Guide
                            }
                            alt="Avatar"
                            className="avatar-image"
                          />
                        </div>
                      </div>
                      <div>
                        <span className="welcome-resident-text">Residence</span>
                        <p className="welcome-resident">
                          {user_type == "residence"
                            ? eventDetail.residence_name
                            : eventDetail.host_name}
                        </p>
                        <div className="welcome-prescreen-date">
                          <p>
                            {formatDay(
                              eventRoomStore.convertTimeFromUTC(
                                eventDetail.time
                              )
                            )}
                          </p>
                          <p>
                            {formatTime(
                              eventRoomStore.convertTimeFromUTC(
                                eventDetail.time_in_utc
                              )
                            )}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            JoinHandler(eventDetail.time_in_utc);
                          }}
                          className="welcome-button"
                          // disabled={!visitEnabled}
                        >
                          Démarrez la visite
                          <KeyboardArrowRightIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};
export default Welcome;
