import React, { useRef, useEffect, useState } from "react";
import { useEventRoomState } from "@/containers/root-container";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./local-audio-control.scss";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./local-audio-control.scss";
import PreviewStreamAudio from "@/components/preview-stream-audio/preview-stream-audio";
import { eventRoomStore } from "@/stores/event";

interface LocalAudioControlProps {
  stream?: any;
  audio: boolean;
}

const LocalAudioControl: React.FC<LocalAudioControlProps> = ({
  stream,
  audio,
}) => {
  const eventRoomState = useEventRoomState();
  const loadAudio = useRef<boolean>(false);
  const [showPreviewStream, setShowPreviewStream] = useState(false);
  // handle audio mute/unmute
  useEffect(() => {
    if (!stream) return;
    // prevent already muted audio
    if (!loadAudio.current) {
      if (!audio) {
        if (stream.audioTrack.enabled) {
          stream.audioTrack.setEnabled(false);
        }
      }
      loadAudio.current = true;
      return;
    }

    if (audio) {
      stream.audioTrack.setEnabled(true);
      sessionStorage.setItem("audioEnabled", "1");
    } else {
      stream.audioTrack.setEnabled(false);
      sessionStorage.setItem("audioEnabled", "0");
    }
  }, [stream, audio]);

  const handleAudioClick = (evt: any) => {
    eventRoomStore.handleStreamControlClick("audio", audio);
  };

  const handlePreviewStream = () => {
    setShowPreviewStream((prevCheck) => !prevCheck);
  };

  return (
    <span className="audio stream-control">
      <span className="audio-button-container stream-control-container">
        <span
          onClick={handleAudioClick}
          className="audio-button-mic_icon stream-control-primary_icon"
        >
          {audio ? <MicIcon /> : <MicOffIcon />}
        </span>
        <span className="audio-button-arrow_icon stream-control-secondary_icon">
          {showPreviewStream ? (
            <KeyboardArrowDownIcon onClick={handlePreviewStream} />
          ) : (
            <KeyboardArrowUpIcon onClick={handlePreviewStream} />
          )}
        </span>
      </span>
      {eventRoomState.me.info.role == "guide" ? (
        <p className="icons-tip">{audio ? "Mute" : "Unmute"}</p>
      ) : (
        <p className="icons-tip">{audio ? "Silence" : "Son"}</p>
      )}
      {showPreviewStream && (
        <PreviewStreamAudio handlePreviewStream={handlePreviewStream} />
      )}
    </span>
  );
};

export default React.memo(LocalAudioControl);
