import React, { useState, useEffect, useCallback } from "react";
import MicIcon from "@material-ui/icons/Mic";
import { eventRoomStore } from "@/stores/event";
import { useEventRoomState } from "@/containers/root-container";
import VoiceVolume from "@/components/preview-stream/voice";
import { AgoraWebClient } from "@/utils/agora-web-client";

import "./preview-stream-audio.scss";

export default function PreviewStreamAudio(props: any) {
  const eventRoomState = useEventRoomState();
  const [microphoneDevices, setMicrophoneDevices] = useState<any>([]);
  const [selectedAud, setSelectedAud] = useState<any>();
  const [tmpStream, setTmpStream] = useState<any>();
  const [volume, setVolume] = useState<number>(0);

  const getDevicesList = async () => {
    const webClient = eventRoomStore.rtcClient as AgoraWebClient;
    let devices = await webClient.getDevices();
    const audioDevices = devices.filter((x: any) => x.kind === "audioinput");
    setMicrophoneDevices(audioDevices);
  };

  useEffect(() => {
    async function setTmpPrevStream() {
      await getDevicesList();
    }

    setTmpPrevStream();
    let setAudInterval: any;
    if (eventRoomState.rtc.localStream?.stream.audioTrack.enabled) {
      console.log(
        "localstream in popup is",
        eventRoomState.rtc.localStream.stream.audioTrack.getVolumeLevel()
      );
      setAudInterval = setInterval(function () {
        setVolume(
          eventRoomState.rtc.localStream?.stream.audioTrack.getVolumeLevel()
        );
      }, 500);
    }
    return () => {
      clearInterval(setAudInterval);
    };
  }, []);

  useEffect(() => {
    async function updateDevicesList() {
      await getDevicesList();
    }
    if (typeof tmpStream != "undefined") {
      if (
        typeof tmpStream.audioTrack != "undefined" &&
        tmpStream.audioTrack != null
      ) {
        tmpStream.audioTrack.setDevice(eventRoomState.currentMicrophone);
        updateDevicesList();
        setAudioDevice(eventRoomState.currentMicrophone);
      }
    }
  }, [eventRoomState.currentMicrophone]);

  useEffect(() => {
    if (
      eventRoomStore._state.rtc.localStream != null &&
      eventRoomStore._state.rtc.localStream.stream.audioTrack._config
        .microphoneId
    ) {
      setSelectedAud(
        eventRoomStore._state.rtc.localStream.stream.audioTrack._config
          .microphoneId
      );
    }
  }, [eventRoomStore._state.rtc.localStream]);

  const setAudioDevice = (e: any) => {
    const localStream = eventRoomState.rtc.localStream as any;
    localStream.stream.audioTrack.setDevice(e.target.value);
    setSelectedAud(e.target.value);
  };

  const setLocalStreamDevices = () => {
    const localStream = eventRoomState.rtc.localStream as any;
    if (typeof selectedAud != "undefined" && selectedAud != "") {
      setTimeout(() => {
        localStream.stream.audioTrack.setDevice(selectedAud);
        window.sessionStorage.setItem("microphoneId", selectedAud);
      }, 1000);
    }
    props.handlePreviewStream();
  };

  const MicrophoneVolume = useCallback(() => {
    return (
      <div className="volume">
        <MicIcon />
        <VoiceVolume volume={volume} />
      </div>
    );
  }, [volume]);

  return (
    <>
      <div className="audio-setting-popup">
        <div className="audio-setting-heading">
          <h1>MICROPHONE</h1>
        </div>
        <div className="audio-setting-middleContainer">
          <select
            onChange={setAudioDevice}
            value={selectedAud}
            className="audio-setting-mic-select audio-setting-select-box"
          >
            {microphoneDevices.map((device: any, index: any) => (
              <option
                value={device.deviceId}
                key={index}
                onSelect={setAudioDevice}
              >
                {device.label}
              </option>
            ))}
          </select>
          <MicrophoneVolume />
        </div>
        <div className="video-setting-footer">
          <div
            className="video-setting-footer__cancel"
            onClick={props.handlePreviewStream}
          >
            Cancel
          </div>
          <div
            className="video-setting-footer__apply"
            onClick={setLocalStreamDevices}
          >
            Ok
          </div>
        </div>
      </div>
    </>
  );
}
