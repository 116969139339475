import React from "react";
import "./WelcomeLoader.scss";

const WelcomeLoader = () => {
  return (
    <div className="center-body">
      <div className="loader-ball-12">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default WelcomeLoader;
