import React from "react";
import Logo from "@/assets/logo.png";
import "./header.scss";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { eventRoomStore } from "@/stores/event";

const HeaderGuide = () => {
  const history = useHistory();

  return (
    <div className="header-guide">
      <div className="header-guide-logo">
        <img src={Logo} />
      </div>
      <div>
        <button onClick={eventRoomStore.logout} className="header-guide-logout">
          LOGOUT
        </button>
      </div>
    </div>
  );
};
export default HeaderGuide;
