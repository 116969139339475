import React from "react";
import FullScreen from "@/components/fullscreen/fullscreen";
import LocalAudioControl from "@/components/local-audio-control/local-audio-control";
import LocalVideoControl from "@/components/local-video-control/local-video-control";
import LocalChatControl from "@/components/local-chat-control/local-chat-control";
import ExitControl from "@/components/exit-control/exit-control";
import "./host-options.scss";
import { useMediaQuery } from "react-responsive";
import LocalVideoCameraFlipControl from "@/components/local-camera-flip-control";
import { isAndroid, isIOS } from "react-device-detect";

interface HostBottomOptionsProps {
  stream?: any;
  audio: boolean;
  video: boolean;
  fullScreenElemId?: string;
  handleChat?: any;
  handlePopUp?: any;
  handlesideStream?: any;
  setfullscreenStream?: any;
}

const HostBottomOptions: React.FC<HostBottomOptionsProps> = ({
  stream,
  audio,
  video,
  fullScreenElemId,
  handleChat,
  handlePopUp,
  handlesideStream,
  setfullscreenStream,
}) => {
  return (
    <>
      <div className="control-options">
        <LocalVideoControl stream={stream} video={video} />
        <LocalAudioControl stream={stream} audio={audio} />
        <ExitControl handlePopUp={handlePopUp} />
      </div>
      <div className="host-right-icons _right">
        {/* {(isAndroid || isIOS) && (
          <LocalVideoCameraFlipControl stream={stream} video={video} />
        )} */}
        <LocalChatControl handleChat={handleChat} />
        <FullScreen
          setfullscreenStream={setfullscreenStream}
          elemId={fullScreenElemId}
          handlesideStream={handlesideStream}
        />
      </div>
    </>
  );
};

export default React.memo(HostBottomOptions);
