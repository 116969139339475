import React, { useState, useEffect, useRef } from "react";
import { CurrentTime } from "@/utils/helper";

export default function Timer(props: any) {
  const [currentTime, setcurrentTime] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setcurrentTime(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="timer">{CurrentTime(currentTime)}</div>
    </>
  );
}
