import React, { useState, useEffect, useCallback, useRef } from "react";
import "./PreCallScreen.scss";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MicIcon from "@mui/icons-material/Mic";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VoiceVolume from "@/components/preview-stream/voice";
import { eventRoomStore } from "@/stores/event";
import { AgoraWebClient } from "@/utils/agora-web-client";
import AgoraRTC from "agora-rtc-sdk-ng";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import Logo from "@/assets/logo.png";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import AccessAllowedError from "../../components/access-allowed/error";
import PreCallScreenPopUp from "./PrecallPopup";
import { isIOS } from "react-device-detect";

interface PrecallScreenProps {
  JoinHandle?: any;
}

const PreCallScreen: React.FC<PrecallScreenProps> = ({ JoinHandle }) => {
  const selectedCam = useRef<string>();
  const selectedAud = useRef<string>();
  const selectedSpeaker = useRef<string>();
  const isRecording = useRef<any>(false);
  const [devicesList, setDevicesList] = useState<any>({
    cameraDevices: [],
    microphoneDevices: [],
    speakerDevices: [],
  });
  const [tmpStream, setTmpStream] = useState<any>();
  const [volume, setVolume] = useState<number>(0);
  const [isLoader, setisLoader] = useState<boolean>(true);
  const [accessAllowed, setAccessAllowed] = useState<boolean>(true);
  const [isDeviceErr, setIsDeviceErr] = useState<boolean>(false);
  const deviceLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (eventRoomStore._state.me.info.role && isDeviceErr) {
      eventRoomStore.handleDeviceError();
    }
  }, [eventRoomStore._state.me, isDeviceErr]);

  const getDevicesList = async () => {
    const webClient = eventRoomStore.rtcClient as AgoraWebClient;
    let devices = await webClient.getDevices();
    let camDevices = devices.filter((x: any) => x.kind === "videoinput");
    const audioDevices = devices.filter((x: any) => x.kind === "audioinput");
    const audioOutDevices = devices.filter(
      (x: any) => x.kind === "audiooutput"
    );
    if (isIOS && eventRoomStore._state.me.info.role == "guide") {
      const frontCameras = camDevices.filter((cam: any) =>
        cam.label.toString().toLowerCase().includes("front")
      );
      const backCameras = camDevices.filter((cam: any) =>
        cam.label.toString().toLowerCase().includes("back")
      );
      camDevices = [];
      if (frontCameras.length) {
        camDevices.push(frontCameras[0]);
      }
      if (backCameras.length) {
        camDevices.push(backCameras[0]);
      }
    }
    deviceLoaded.current = true;
    setDevicesList({
      cameraDevices: camDevices,
      microphoneDevices: audioDevices,
      speakerDevices: audioOutDevices,
    });
  };
  useEffect(() => {
    async function setTmpPrevStream() {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function () {
          console.log("Permission to access  is granted.");
        })
        .catch(function (error) {
          setAccessAllowed(false);
          console.log("Permission to access  is not granted:", error);
        });

      await getDevicesList();
    }
    setTmpPrevStream();
  }, [AgoraRTC.getDevices]);

  useEffect(() => {
    async function handleTmpStream() {
      if (devicesList.cameraDevices.length) {
        const selectedMicrophoneId = "";
        const selectedCameraId = "";
        let audioTrack = null as any;
        let videoTrack = "";
        try {
          videoTrack = await AgoraRTC.createCameraVideoTrack({
            optimizationMode: "motion",
            cameraId: selectedCameraId,
          });
          audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: selectedMicrophoneId,
            AEC: true, // acoustic echo cancellation
            AGC: true, // audio gain control
            ANS: true, // automatic noise suppression
            encoderConfig: "speech_standard",
          });
          setTmpStream({ audioTrack, videoTrack });
        } catch (e) {
          setIsDeviceErr(true);
        }
      } else {
        setIsDeviceErr(true);
      }
      setisLoader(false);
    }
    if (deviceLoaded.current) {
      handleTmpStream();
    }
  }, [devicesList]);

  useEffect(() => {
    let setAudInterval: any;
    if (tmpStream) {
      tmpStream.videoTrack.play("local-preview");
      if (tmpStream?.audioTrack) {
        setAudInterval = setInterval(function () {
          setVolume(tmpStream.audioTrack.getVolumeLevel());
        }, 1000);
      }
    }
    return () => {
      if (tmpStream) {
        if (tmpStream?.videoTrack) {
          tmpStream.videoTrack.stop();
          tmpStream.videoTrack.close();
        }
        if (tmpStream?.audioTrack) {
          tmpStream.audioTrack.stop();
          tmpStream.audioTrack.close();
        }
      }
      clearInterval(setAudInterval);
    };
  }, [tmpStream]);

  const setCameraDevice = (e: any) => {
    tmpStream.videoTrack.setDevice(e.target.value);
    selectedCam.current = e.target.value;
  };

  const setAudioDevice = (e: any) => {
    tmpStream.audioTrack.setDevice(e.target.value);
    selectedAud.current = e.target.value;
  };

  const setSpeakerDevice = (e: any) => {
    selectedSpeaker.current = e.target.value;
  };
  const setRecording = (e: any) => {
    isRecording.current = e.target.value;
  };

  const setLocalStreamDevices = () => {
    if (devicesList.cameraDevices.length == 0) {
      eventRoomStore.handleDeviceError();
      return;
    }
    window.sessionStorage.setItem(
      "cameraId",
      selectedCam.current || devicesList?.cameraDevices?.[0]?.deviceId || ""
    );
    window.sessionStorage.setItem(
      "microphoneId",
      selectedAud.current || devicesList?.microphoneDevices?.[0].deviceId || ""
    );
    {
      devicesList?.speakerDevices.length > 0 &&
        window.sessionStorage.setItem(
          "speakerId",
          selectedSpeaker.current || devicesList?.speakerDevices[0].deviceId
        );
    }
    sessionStorage.setItem("recording", isRecording.current);

    JoinHandle();
  };

  const MicrophoneVolume = useCallback(() => {
    return <VoiceVolume volume={volume} />;
  }, [volume]);

  return (
    <>
      <div className="precall-screen">
        <div className="precall-screen-logo">
          <img src={Logo} />
        </div>
        {!accessAllowed ? (
          <AccessAllowedError />
        ) : (
          <div className="precall-screen-content">
            <div id="local-preview" className="local-stream-preview"></div>
            {isLoader ? (
              <WelcomeLoader />
            ) : (
              <div className="precall-screen-options">
                {eventRoomStore._state.me.info.role == "guide" && (
                  <PreCallScreenPopUp />
                )}
                <div className="precall-screen-text">
                  {eventRoomStore._state.me.info.role == "guide"
                    ? "Please select your device"
                    : "Sélectionnez votre appareil"}
                </div>
                {devicesList?.cameraDevices.length > 0 && (
                  <div className="precall-screen-option">
                    <label>
                      <CameraAltIcon />
                    </label>
                    <select
                      onChange={setCameraDevice}
                      className="precall-screen-select"
                    >
                      {devicesList?.cameraDevices.map(
                        (device: any, index: any) => (
                          <option
                            value={device.deviceId}
                            key={index}
                            onSelect={setCameraDevice}
                          >
                            {device.label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
                <div className="precall-screen-option">
                  <label>
                    <MicIcon />
                  </label>
                  <select
                    onChange={setAudioDevice}
                    className="precall-screen-select"
                  >
                    {devicesList?.microphoneDevices.map(
                      (device: any, index: any) => (
                        <option
                          value={device.deviceId}
                          key={index}
                          onSelect={setAudioDevice}
                        >
                          {device.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <MicrophoneVolume />
                {devicesList?.speakerDevices.length > 0 && (
                  <div className="precall-screen-option">
                    <label>
                      <VolumeUpIcon />
                    </label>
                    <select
                      onChange={setSpeakerDevice}
                      className="precall-screen-select"
                    >
                      {devicesList?.speakerDevices.map(
                        (device: any, index: any) => (
                          <option
                            value={device.deviceId}
                            key={index}
                            onSelect={setSpeakerDevice}
                          >
                            {device.label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
                {eventRoomStore._state.me.info.role == "host" && (
                  <div className="precall-screen-option">
                    <label>
                      <GraphicEqIcon />
                    </label>
                    <select
                      onChange={setRecording}
                      className="precall-screen-select"
                    >
                      <option value={"false"} onSelect={setRecording}>
                        False
                      </option>
                      <option value={"true"} onSelect={setRecording}>
                        True
                      </option>
                    </select>
                  </div>
                )}
                <button
                  onClick={setLocalStreamDevices}
                  className="precall-screen-button"
                >
                  {eventRoomStore._state.me.info.role == "guide"
                    ? "Join Now"
                    : "Rejoindre"}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default PreCallScreen;
